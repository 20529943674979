import Navigation from './navigation'
import Metamask from './metamask'
import isMobile from 'is-mobile'
import { abbrevAddr } from '../util/string'

export default class Header {
    private fadeTimer: NodeJS.Timeout
    public metamask: Metamask

    constructor(ghostId: number | null) {
        new Navigation(this.getFadeTimer, document.querySelector('header'))
        window.scrollTo(0, 0)

        this.metamask = new Metamask(
            process.env.CHAIN_ID,
            process.env.CONTRACT_ADDRESS || "",
            process.env.MEMORIES_STORAGE_ADDRESS || "",
            process.env.FRAGMENT_ADDRESS || "",
            process.env.GOOD_MEMORIES_ADDRESS || "",
            process.env.EVIL_MEMORIES_ADDRESS || "",
            process.env.SPECIAL_FRAGMENT_ADDRESS || "",
            ghostId || 0
        )

        document.body.onscroll = () => {
            clearTimeout(this.fadeTimer)
            if (window.scrollY > 0) {
                document.querySelector('header').classList.add('fadeIn')
                this.fadeTimer = setTimeout(() => {
                    document.querySelector('header').classList.remove('fadeIn')
                    document.querySelector('header').classList.add('fadeOut')
                }, 500)
            } else if (window.scrollY === 0) {
                document.querySelector('header').classList.remove('fadeOut')
                document.querySelector('header').classList.add('fadeIn')
            }
            this.setWalletPanelVisible(false)
        }

        document.body.onmousemove = () => {
            clearTimeout(this.fadeTimer)
            if (!document.getElementById('walletPanel').classList.contains('hidden')) {
                return
            }
            if (window.scrollY === 0) return
            if (document.body.classList.contains('noscroll')) return
            document.querySelector('header').classList.remove('fadeOut')
            document.querySelector('header').classList.add('fadeIn')
            this.fadeTimer = setTimeout(() => {
                document.querySelector('header').classList.remove('fadeIn')
                document.querySelector('header').classList.add('fadeOut')
            }, 500)
        }

        document.body.onclick = () => {
            this.setWalletPanelVisible(false)
        }

        document.querySelector('#walletPanelArea').addEventListener('click', (event) => {
            event.stopPropagation()
        })

        document.getElementById('searchIcon').onclick = () => {
            const searchBox = document.getElementById('searchBox')
            if (searchBox.classList.contains('hidden'))
                searchBox.classList.remove('hidden')
            else
                searchBox.classList.add('hidden')
        }

        document.getElementById('searchEnter').onclick = () => {
            const ghostId = parseInt((document.getElementById('searchText') as HTMLInputElement).value)
            this.redirectToDetailPage(ghostId)
        }

        document.getElementById('searchText').addEventListener("keyup", (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                document.getElementById("searchEnter").click();
            }
        })

        document.getElementById('searchText').oninput = (event: InputEvent) => {
            const target = event.target as HTMLInputElement
            let value = target.value
            value = value.replace(/[^0-9]/g, '')
            target.value = value
        }


        document.querySelector('#connectWallet').addEventListener('click', (event) => {
            if (isMobile()) {
                window.alert('Wallet connection is only supported on the PC. Please try again on the PC.')
                return
            }
            this._connectWallet(event)
            // document.querySelector('header').classList.remove('fadeOut')
            // const panel = document.querySelector('#walletPanel')
            // if (this.metamask.account) {
            //     this.metamask.account = null
            //     document.getElementById('connectWallet').innerText = 'CONNECT WALLET'
            //     panel.classList.add('hidden')
            // } else {
            //     if (panel.classList.contains('hidden')) panel.classList.remove('hidden')
            //     else panel.classList.add('hidden')
            //     document.body.dispatchEvent(new MouseEvent('mousemove'))
            // }
            // event.stopPropagation()
        })

        document.getElementById('callMetamask').onclick = (event) => {
            this.metamask.callMetamask()
            event.stopPropagation()
        }
        document.getElementById('connectWallet').onmouseenter = () => {
            if (this.metamask.account) {
                document.getElementById('connectWallet').innerText = 'DISCONNECT'
            }
        }
        document.getElementById('connectWallet').onmouseleave = () => {
            if (this.metamask.account) {
                document.getElementById('connectWallet').innerText = abbrevAddr(this.metamask.account)
            }
        }

        document.querySelector('#closeWallet').addEventListener('click', (event) => {
            this.setWalletPanelVisible(false)
            event.stopPropagation()
        })
    }

    _connectWallet = (event: Event) => {
        document.querySelector('header').classList.remove('fadeOut')
        const panel = document.querySelector('#walletPanel')
        if (this.metamask.account) {
            this.metamask.account = null
            document.getElementById('connectWallet').innerText = 'CONNECT WALLET'
            panel.classList.add('hidden')
        } else {
            if (panel.classList.contains('hidden')) panel.classList.remove('hidden')
            else panel.classList.add('hidden')
            document.body.dispatchEvent(new MouseEvent('mousemove'))
        }
        event.stopPropagation()
    }

    redirectToDetailPage = (ghostId: number) => {
        if ((ghostId >= 0) && (ghostId < 10000)) {
            window.location.href = `/detail.html?id=${ghostId}`
            // window.open(`/detail.html?id=${ghostId}`)  // use if want to open in new window
        }
    }

    setWalletPanelVisible = (flag: Boolean) => {
        const panel = document.querySelector('#walletPanel')
        if (flag) {
            if (panel.classList.contains('hidden')) panel.classList.remove('hidden')
        } else {
            panel.classList.add('hidden')
        }
    }

    getFadeTimer = (): NodeJS.Timeout => {
        return this.fadeTimer
    }

}
