import SummonPhase2 from './summonPhase2'
import { loadSummonPhase, unloadSummonPhase } from '../util/summonLoader'
import { zip } from 'rxjs'
import Metamask from './metamask'

export default class SummonPhase1 {
    private metamask: Metamask
    private satisfied: boolean = false

    constructor(metamask: Metamask) {
        this.metamask = metamask
        document.getElementById('summonPanel').onclick = (event) => {
            event.stopPropagation()
        }
        document.getElementById('nextStep').onclick = () => {
            if (this.satisfied) {
                unloadSummonPhase()
                zip(loadSummonPhase('summon_phase2.html')).subscribe(() => {
                    new SummonPhase2(this.metamask)
                })
            }
        }
        document.getElementById('closePopup').onclick = () => {
            document.querySelector('#overlay').classList.add('overlayHidden')
            unloadSummonPhase()
        }
        this.getWeb3ClientInfo()
    }

    updateLinks = (ghostBalance: number) => {
        if (this.metamask.account && ghostBalance > 0) {
            let openseaUrl: string
            let etherscanUrl: string
            if (process.env.CHAIN_ID === '0x1') {
                openseaUrl = `https://opensea.io/${this.metamask.account}?search[sortBy]=LISTING_DATE&search[query]=GhostsProject`
                etherscanUrl = `https://etherscan.io/address/${this.metamask.account}`
            } else if (process.env.CHAIN_ID === '0x4') {
                openseaUrl = `https://testnets.opensea.io/${this.metamask.account}?search[sortBy]=LISTING_DATE&search[query]=GhostsProjectRinkeby`
                etherscanUrl = `https://rinkeby.etherscan.io/address/${this.metamask.account}`
            }
            document.getElementById('openseaLink').onclick = () => {
                window.open(openseaUrl, '_blank').focus()
            }
            document.getElementById('etherscanLink').onclick = () => {
                window.open(etherscanUrl, '_blank').focus()
            }
            document.getElementById('openseaLink').classList.remove('noghost')
            document.getElementById('etherscanLink').classList.remove('noghost')
        } else {
            // change opacity
            document.getElementById('openseaLink').classList.add('noghost')
            document.getElementById('etherscanLink').classList.add('noghost')
        }
    }

    getWeb3ClientInfo = async () => {
        const sumInTotal = (values: number[]) => {
            let total = 0
            values.forEach((value) => (total += value))
            return total
        }

        const aggregateTickets = (hoursLeft: number[], tickets: number[]): [number[], number[]] => {
            const tmpHoursLeft: number[] = []
            hoursLeft.forEach((hourLeft) => {
                if (hourLeft < 24) tmpHoursLeft.push(hourLeft)
                else tmpHoursLeft.push(hourLeft - (hourLeft % 24))
            })
            const aggHoursLeft: number[] = []
            const aggTickets: number[] = []
            let ticketTmp = 0
            tickets.forEach((ticket, idx) => {
                ticketTmp += ticket
                if (idx === tickets.length - 1 || tmpHoursLeft[idx] !== tmpHoursLeft[idx + 1]) {
                    if (ticketTmp > 0) {
                        aggHoursLeft.push(tmpHoursLeft[idx])
                        aggTickets.push(ticketTmp)
                        ticketTmp = 0
                    }
                }
            })
            let ticketsPopped = 0
            while (aggTickets.length > 5) {
                ticketsPopped += aggTickets.pop()
                aggHoursLeft.pop()
            }
            if (ticketsPopped > 0) aggTickets[4] += ticketsPopped
            return [aggHoursLeft, aggTickets]
        }

        if (this.metamask.account) {
            this.metamask.getGhostBalance(this.metamask.account).then((ghostBalance: number) => {
                document.getElementById('claimedGhosts').innerHTML = `${ghostBalance} Ghosts`
                this.updateLinks(ghostBalance)
            })

            const currentBlockNumber = await this.metamask.getCurrentBlockNumber()
            Promise.all(this.metamask.getTicketsPerRound(this.metamask.account, currentBlockNumber))
                .then(([hoursLeft, ticketsAvailable]) => {
                    return aggregateTickets(hoursLeft, ticketsAvailable)
                })
                .then(([aggHoursLeft, aggTickets]) => {
                    let innerHtml = '<p class="title">Expires in</p>'
                    let innerHtmlTickets = '<p class="title">Quantity</p>'
                    aggHoursLeft.forEach((hourLeft, idx) => {
                        if (hourLeft === 0) innerHtml += `<p class="item red">less than an hour</p>`
                        else if (hourLeft === 1) innerHtml += `<p class="item red">1 hour</p>`
                        else if (hourLeft < 24) innerHtml += `<p class="item red">${hourLeft} hours</p>`
                        else if (hourLeft < 48) innerHtml += `<p class="item grey">1 day</p>`
                        else innerHtml += `<p class="item grey">${Math.floor(hourLeft / 24)} days</p>`
                        innerHtmlTickets += `<p class="item">${aggTickets[idx].toString()}</p>`
                    })
                    document.getElementById('expiresIn').innerHTML = innerHtml
                    document.getElementById('ticketsAgg').innerHTML = innerHtmlTickets
                    document.getElementById('nextStep').innerText = `Total Available Tickets : ${sumInTotal(aggTickets)}  ➔ `
                    if (sumInTotal(aggTickets) > 0) {
                        this.satisfied = true
                        document.getElementById('nextStep').classList.remove('disabled')
                        this.setNoticket(false)
                    } else {
                        document.getElementById('nextStep').classList.add('disabled')
                        this.setNoticket(true)
                    }
                })
        }
    }

    setNoticket(flag: boolean) {
        if (flag) {
            document.getElementById('noticketPanel').classList.remove('hidden')
            document.getElementById('titleSp1').classList.add('title_noticket')
            document.getElementById('descSp1').classList.add('desc_noticket')
        } else {
            document.getElementById('noticketPanel').classList.add('hidden')
            document.getElementById('titleSp1').classList.remove('title_noticket')
            document.getElementById('descSp1').classList.remove('desc_noticket')
        }
    }
}
