import { unloadSummonPhase } from '../util/summonLoader'

export default class UnderMaintenance {
    constructor() {
        document.getElementById('closePopup').onclick = () => {
            document.querySelector('#overlay').classList.add('overlayHidden')
            unloadSummonPhase()
        }
        document.getElementById('nextStep').onclick = () => {
            window.open("https://discord.com/channels/872721424358461461/873113286525222942/929791963941711942", "_blank")
        }
    }
}
