import { zip } from 'rxjs'
import Metamask from './metamask'
import { loadMemoriesPhase, unloadMemoriesPhase } from '../util/memoriesPhaseLoader'
import MemoriesPhase3Manual from './memoriesPhase3Manual'
import MemoriesPhase2 from './memoriesPhase2'
import { MemoryData, MemoryType } from '../util/memoryData'
import MemoriesPhase4 from './memoriesPhase4'

export default class MemoriesPhase3Auto {
    private metamask: Metamask

    constructor(metamask: Metamask, ghostIds: number[]) {
        this.metamask = metamask
        document.getElementById('memoriesPanel').onclick = (event) => {
            event.stopPropagation()
        }
        document.getElementById('closePopup').onclick = () => {
            document.getElementById('overlay').classList.add('overlayHidden')
            document.getElementById('memoriesSection').classList.remove('noOverFlow')
            unloadMemoriesPhase()
        }

        document.getElementById('flashbackHelp').onmouseover = () => {
            document.getElementById('flashbackHelpBox').classList.remove('hidden')
        }
        document.getElementById('flashbackHelp').onmouseleave = () => {
            document.getElementById('flashbackHelpBox').classList.add('hidden')
        }

        document.getElementById('manualChoose').onclick = () => {
            unloadMemoriesPhase()
            zip(loadMemoriesPhase('memories_phase3_manual.html')).subscribe(() => {
                new MemoriesPhase3Manual(this.metamask, ghostIds)
            })
        }
        document.getElementById('prevStep').onclick = () => {
            unloadMemoriesPhase()
            zip(loadMemoriesPhase('memories_phase2.html')).subscribe(() => {
                new MemoriesPhase2(this.metamask, ghostIds)
            })
        }
        document.getElementById('nextStep').onclick = async () => {
            const memoryData = await this.getMemoriesFromWeb3(ghostIds)
            const memories = this.randomPick(memoryData)
            unloadMemoriesPhase()
            zip(loadMemoriesPhase('memories_phase4.html')).subscribe(() => {
                new MemoriesPhase4(this.metamask, memories)
            })
        }
    }

    getMemoriesFromWeb3 = async (ghostIds: number[]) => {
        let memories: MemoryData[] = []

        const memoriesPromise: Promise<any>[] = []
        for (const ghostId of ghostIds) {
            for (const memoryType of ["Good", "Evil"])
            memoriesPromise.push(
                this.metamask.getGhostCandidateMemories(ghostId, memoryType)
                    .then((memories) => {
                        return {
                            id: ghostId,
                            memoryType: memoryType,
                            memories: memories
                        }
                    })
                    .catch((err: any) => {
                        console.log(err)
                    })
            )
        }

        ghostIds.forEach((ghostId) => {
            memories.push({
                ghostId: ghostId,
                memoryType: MemoryType.UNKNOWN,
                evilMemory: "",
                goodMemory: "",
                evilFlashback: "",
                goodFlashback: ""
            } as MemoryData)
        })

        const memoriesFull = await Promise.all(memoriesPromise)
        memoriesFull.forEach((memory) => {
            const idx = ghostIds.indexOf(memory.id)
            let memoryPiece = memory.memories
            let flashback = ""
            if (memoryPiece.includes("[FB]")) {
                [memoryPiece, flashback] = memoryPiece.split("[FB]").slice(0, 2)
            }
            if (memory.memoryType === "Good") {
                memories[idx].goodMemory = memoryPiece
                memories[idx].goodFlashback = flashback
            } else {
                memories[idx].evilMemory = memoryPiece
                memories[idx].evilFlashback = flashback
            }
        })

        return memories
    }

    randomPick = (memories: MemoryData[]) => {
        let random = Math.random() - 0.5
        memories.forEach((memory: MemoryData) => {
            console.log(random)
            memory.memoryType = (random > 0) ? MemoryType.GOOD : MemoryType.EVIL
            random = 2 * Math.abs(random) - 0.5
        })
        return memories
    }
}
