import { Subject } from 'rxjs'

export function loadMemoriesPhase(page: string) {
    return Array.from(document.getElementsByTagName('div'))
        .filter((elem) => elem.hasAttribute('memories-placeholder'))
        .map((elem) => {
            const eventEmitter = new Subject()
            fetch(page)
                .then((response) => {
                    return response.text()
                })
                .then((html) => {
                    elem.insertAdjacentHTML('afterend', html)
                    elem.parentElement.removeChild(elem)
                    eventEmitter.next(true)
                })
                .catch((err) => {
                    console.log('error in fetching summon html', page, err)
                })
            return eventEmitter
        })
}

export function unloadMemoriesPhase() {
    Array.from(document.getElementsByTagName('div'))
        .filter((elem) => elem.hasAttribute('memories-placeholder'))
        .forEach((elem) => {
            elem.classList.add('overlayHidden')
            elem.childNodes.forEach((child) => {
                elem.removeChild(child)
            })
        })
}
