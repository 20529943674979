import { BigNumber, ethers } from 'ethers'
import { zip } from 'rxjs'
import detectEthereumProvider from '@metamask/detect-provider'
import { abbrevAddr } from '../util/string'
import { loadSummonPhase, unloadSummonPhase } from '../util/summonLoader'
import SummonPhase4 from './summonPhase4'
import SummonPhase5 from './summonPhase5'
import { Attribute, EtherscanResponse, EthLog, TokenUri } from '../util/tokenUriInterface'
import { loadMemoriesPhase, unloadMemoriesPhase } from '../util/memoriesPhaseLoader'
import MemoriesPhase6 from './memoriesPhase6'
import { MemoryData } from '../util/memoryData'
import { loadClaimPhase, unloadClaimPhase } from '../util/claimPhaseLoader'
import ClaimPhase3 from './claimPhase3'

export default class Metamask {
    private contractAddr: string
    private fragmentContractAddr: string
    private goodMemoriesAddr: string
    private evilMemoriesAddr: string
    private memoriesStorageAddr: string
    private specialFragmentAddr: string
    private chainId: string
    private ghostId: number
    private contract: ethers.Contract
    private contractInterface: ethers.utils.Interface
    private storageContract: ethers.Contract
    private storageContractInterface: ethers.utils.Interface
    private fragmentContract: ethers.Contract
    private fragmentContractInterface: ethers.utils.Interface
    private goodMemoriesContract: ethers.Contract
    private evilMemoriesContract: ethers.Contract
    private specialFragmentContract: ethers.Contract
    private specialFragmentContractInterface: ethers.utils.Interface
    private ethProvider: any
    private web3Provider: ethers.providers.Web3Provider

    public account: string
    public presaleIsActive: boolean
    public saleIsActive: boolean
    public soldout: boolean
    private targetDate: number
    public isReady: boolean = false

    constructor(
        chainId: string,
        contractAddr: string,
        memoriesStorageAddr: string,
        fragmentContractAddr: string,
        goodMemoriesAddr: string,
        evilMemoriesAddr: string,
        specialFragmentAddr: string,
        ghostId: number = 0
    ) {
        this.contractAddr = contractAddr
        this.memoriesStorageAddr = memoriesStorageAddr
        this.fragmentContractAddr = fragmentContractAddr
        this.goodMemoriesAddr = goodMemoriesAddr
        this.evilMemoriesAddr = evilMemoriesAddr
        this.specialFragmentAddr = specialFragmentAddr
        this.chainId = chainId
        this.ghostId = ghostId
        this.targetDate = Date.UTC(2022, 0, 24, 0, 0, 0)  // need to change date 4 to 24
        this.handleEvents()
        this.checkMetamask()
    }

    initContract = () => {
        const abiMap = {
            GhostsProject: [
                'function name() view returns (string)',
                'function symbol() view returns (string)',
                'function totalSupply() view returns (uint)',
                'function MAX_GHOSTS() view returns (uint)',
                'function ghostPrice() view returns (uint)',
                'function presaleIsActive() view returns (bool)',
                'function saleIsActive() view returns (bool)',
                'function balanceOf(address) view returns (uint)',
                'function tokenOfOwnerByIndex(address, uint) view returns (uint)',
                'function getPioneerTicketAvailable(address) view returns (uint)',
                'function getPioneerTicketAvailablePerRound(address) view returns (uint[])',
                'function getPioneerTicketClaimed(address) view returns (uint[])',
                'function getPioneerRoundExpireBlocks() view returns (uint[])',
                'function mintGhostForPioneer(uint)',
                'function mintGhost(uint)',
                'function hasMemory(uint) view returns (bool)',
                'function ownerOf(uint) view returns (address)',
                'function tokenURI(uint) view returns (string)',
                'function pickMemory(uint256, uint8, string)',
                'function memoryPicked(uint256) view returns (string)',
                'function getMemoryType(uint256) view returns (uint)',
                'function countGoodMemories() view returns (uint)',
                'function countEvilMemories() view returns (uint)',
                // An event triggered whenever anyone transfers to someone else
                'event Transfer(address indexed from, address indexed to, uint amount)',
                'event PickMemory(uint256 indexed tokenId, string memoryPhrase)'
            ],
            Fragment: [
                'function balanceOf(address) view returns (uint)',
                'function totalSupply() view returns (uint)',
                'function tokenByIndex(uint) view returns (uint)',
                'function tokenOfOwnerByIndex(address, uint) view returns (uint)',
                'function ownerOf(uint) view returns (address)',
                'function lotteryState() view returns (uint)',
                'function hasCorrectMemory(uint) view returns (bool)',
                'function runLotteryBatch(uint[])',
                'function getLotteryResult(uint) view returns (bool)',
                'function hasParticipatedLottery(uint) view returns (bool)',
            ],
            Memories: [
                'function getMemoryAndFlashbackByGhostId(uint) view returns (string)'
            ],
            MemoriesStorage: [
                'function countGoodMemories() view returns (uint)',
                'function countEvilMemories() view returns (uint)',
                'function hasMemory(uint) view returns (bool)',
                'function getChosenMemory(uint) view returns (string)',
                'function getMemoryType(uint) view returns (uint)',
                'function chooseMemoryBatch(uint256[], uint8[])',
                'function chooseMemoryAndRunLottery(uint256[], uint8[])',
            ],
            SpecialFragment: [
                'function getTotalSupply(uint) view returns (uint)',
                'function isVerified(uint) view returns (bool)',
                'function claimed(uint) view returns (bool)',
                'function isClaimable(uint) view returns (bool)',
                'function claimSpecialFragments(uint256[])',
            ]
        }

        // The Contract object
        this.contractInterface = new ethers.utils.Interface(abiMap.GhostsProject)
        this.contract = new ethers.Contract(
            this.contractAddr,
            abiMap.GhostsProject,
            new ethers.providers.InfuraProvider(parseInt(process.env.CHAIN_ID, 16))
        )
        this.storageContractInterface = new ethers.utils.Interface(abiMap.MemoriesStorage)
        this.storageContract = new ethers.Contract(
            this.memoriesStorageAddr,
            abiMap.MemoriesStorage,
            new ethers.providers.InfuraProvider(parseInt(process.env.CHAIN_ID, 16))
        )
        this.fragmentContractInterface = new ethers.utils.Interface(abiMap.Fragment)
        this.fragmentContract = new ethers.Contract(
            this.fragmentContractAddr,
            abiMap.Fragment,
            new ethers.providers.InfuraProvider(parseInt(process.env.CHAIN_ID, 16))
        )
        this.goodMemoriesContract = new ethers.Contract(
            this.goodMemoriesAddr,
            abiMap.Memories,
            new ethers.providers.InfuraProvider(parseInt(process.env.CHAIN_ID, 16))
        )
        this.evilMemoriesContract = new ethers.Contract(
            this.evilMemoriesAddr,
            abiMap.Memories,
            new ethers.providers.InfuraProvider(parseInt(process.env.CHAIN_ID, 16))
        )
        this.specialFragmentContractInterface = new ethers.utils.Interface(abiMap.SpecialFragment)
        this.specialFragmentContract = new ethers.Contract(
            this.specialFragmentAddr,
            abiMap.SpecialFragment,
            new ethers.providers.InfuraProvider(parseInt(process.env.CHAIN_ID, 16))
        )
    }

    handleAccountsChanged = (accounts: any[]) => {
        this.account = accounts[0]
        document.getElementById('connectWallet').innerText = abbrevAddr(this.account)

        this.updateFragmentHighlight()
    }

    handleChainChanged = (chainId: any) => {
        window.location.reload()
    }

    handleEvents = (): void => {
        if (window.ethereum) {
            window.ethereum.on('accountsChanged', this.handleAccountsChanged)
            window.ethereum.on('chainChanged', this.handleChainChanged)
        }
    }

    _updateChain = async () => {
        await this.ethProvider
            .request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: process.env.CHAIN_ID }]
            })
            .catch((switchError: any) => {
                console.error(switchError)
            })
    }

    checkMetamask = async () => {
        this.ethProvider = await detectEthereumProvider()
        if (this.ethProvider) {
            this.web3Provider = new ethers.providers.Web3Provider(this.ethProvider)
            document.getElementById('metamaskCaption').innerText = 'Connect with Metamask'
            // if (this.ethProvider.chainId !== process.env.CHAIN_ID) await this._updateChain()
            // if (this.ethProvider.isConnected())
            //     this.callMetamask()
        } else {
            document.getElementById('metamaskCaption').innerText = 'Install Metamask'
        }
        this.initContract()
        this.isReady = true
        await this.getWeb3BaseInfo()
        await this.getGhostInfoFromWeb3()
        // this.countDown()
    }

    callMetamask = () => {
        const tmpThis = this
        if (this.ethProvider) {
            this.ethProvider.request({ method: 'eth_requestAccounts' }).then((accounts: string[]) => {
                if (accounts.length > 0) {
                    tmpThis.account = accounts[0]
                    document.getElementById('connectWallet').innerText = abbrevAddr(accounts[0])
                    document.getElementById('walletPanel').classList.add('hidden')
                    this.updateFragmentHighlight()
                    // if (this.ethProvider.chainId !== process.env.CHAIN_ID) // activate when ready to possess
                    //     window.alert("You need to update chain to Ethereum Mainnet.")
                }
            })
        } else {
            window.open('https://metamask.io/', '_blank').focus()
        }
    }

    getWeb3BaseInfo = async () => {
        if (document.getElementById('ghostPlaceholder')) {
            const totalSupply = await this.contract.totalSupply()
            this.soldout = (totalSupply == 10000)
            document.getElementById('ghostPlaceholder')?.remove()
            if (document.getElementById('ghostLeft'))
                document.getElementById('ghostLeft').innerText = `${10000 - totalSupply} / 10000 LEFT`
            if (document.getElementById('ghostSupply'))
                document.getElementById('ghostSupply').innerText = `${totalSupply} / 10000`
            if (document.getElementById('ghostSummoned'))
                document.getElementById('ghostSummoned').innerText = `${totalSupply} GHOSTS HAVE BEEN SUMMONED`

            this.presaleIsActive = await this.contract.presaleIsActive()
            this.saleIsActive = await this.contract.saleIsActive()
            const nowDate: number = new Date().valueOf()
            if (!this.soldout && (this.presaleIsActive || (this.targetDate < nowDate && this.saleIsActive))) {
                document.getElementById('summonButton')?.classList.remove('buttonDisabled')
                document.getElementById('summonButton').classList.add('button')
            } else {
                document.getElementById('summonButton').classList.add('buttonDisabled')
                document.getElementById('summonButton').classList.remove('button')
            }
        }
    }

    getGhostCandidateMemories = async (ghostId: number, memoryType: string): Promise<string> => {
        if (memoryType === "Good")
            return this.goodMemoriesContract.getMemoryAndFlashbackByGhostId(ghostId)
        return this.evilMemoriesContract.getMemoryAndFlashbackByGhostId(ghostId)
    }

    getGhostInfoFromWeb3 = async () => {
        if (window.location.pathname === '/detail.html') {
            this.ownerOfGhost(this.ghostId).then((ghostOwner: string) => {
                document.getElementById('ownerAddress').innerText = abbrevAddr(ghostOwner.toUpperCase())
                document.getElementById('ownerAddress').onclick = () => {
                    window.open(`https://etherscan.io/address/${ghostOwner}`, '_blank').focus()
                }
            })

            this.getOwnershipHistory(this.ghostId)

            this.getTokenUri(this.ghostId).then((tokenIpfsUri: string) => {
                const tokenUri = tokenIpfsUri.replace('ipfs://', 'https://ipfs.io/ipfs/')
                fetch(tokenUri).then((response) => {
                    return response.json()
                }).then((metadata: TokenUri) => {
                    // insert url
                    const imageUri = metadata.image.replace('ipfs://', 'https://ipfs.io/ipfs/')
                    const portraitElem = document.getElementById('ghostPortrait') as HTMLImageElement
                    portraitElem.src = imageUri

                    // insert properties
                    this.setPropertyElem(metadata.attributes)
                })
            })
        }
    }

    ownerOfGhost = (tokenId: number): Promise<string> => {
        return this.contract.ownerOf(tokenId)
    }

    hasMemory = (tokenId: number): Promise<boolean> => {
        return this.storageContract.hasMemory(tokenId)
    }

    getTokenUri = (tokenId: number): Promise<string> => {
        return this.contract.tokenURI(tokenId)
    }

    getOwnershipHistory = (tokenId: number) => {
        const fromBlock = '13705784'
        const toBlock = '99999999'
        const address = process.env.CONTRACT_ADDRESS
        const topic0 = '0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef'
        const topic3 = '0x' + '0'.repeat(64 - tokenId.toString(16).length) + tokenId.toString(16)
        const etherscanUri = `https://api.etherscan.io/api?module=logs&action=getLogs&fromBlock=${fromBlock}&toBlock=${toBlock}&address=${address}&topic0=${topic0}&topic0_3_opr=and&topic3=${topic3}&apikey=YourApiKeyToken`
        fetch(etherscanUri).then((resp) => {
            return resp.json()
        }).then((record: EtherscanResponse) => {
            const logs = record.result.reverse()
            this.setOwnerHistoryElem(logs)
        })
    }

    setPropertyElem = async (attributes: Attribute[]) => {
        const rarityUrl = 'https://ipfs.io/ipfs/QmPsKwdgCzdmnKSqDsLy2r2fEZtbeuWXCvbFCoVudCgrA6'
        const rarityMap: { [item: string]: number } = await fetch(rarityUrl).then((resp) => resp.json())

        const attributeMap: { [id: string]: string[] } = {}
        attributes.forEach((attribute) => {
            if (attribute.trait_type in attributeMap) {
                attributeMap[attribute.trait_type].push(attribute.value)
            } else {
                attributeMap[attribute.trait_type] = [attribute.value]
            }
        })
        const len = Math.ceil(Object.keys(attributeMap).length / 2)

        const propertiesDiv = document.getElementById('properties')
        let count = 0
        let divString = '<div class="column">'
        for (let key in attributeMap) {
            divString += `<div class='property'><div class='category'>${key}</div>`
            attributeMap[key].forEach((value) => {
                divString += `<div class='item'> <div class='name'>${value}</div> <div class='rarity'>${(rarityMap[value] / 100)}%</div> </div>`
            })
            divString += '</div>'
            count += 1
            if (count === len)
                divString += '</div><div class="column">'
        }
        divString += '</div>'
        propertiesDiv.innerHTML = divString
    }

    setOwnerHistoryElem = (logs: EthLog[]) => {
        const currentTime = Date.now() / 1000
        const historyDiv = document.getElementById('histories')
        logs.forEach((log, index) => {
            if (index > 0) {
                historyDiv.innerHTML += "<div class='history-line'></div>"
            }
            const timestamp = parseInt(log.timeStamp, 16)
            const elapsed = Math.floor(currentTime) - timestamp
            let elapsedString: string;
            if (elapsed < 60 * 60)
                elapsedString = `${Math.floor(elapsed / 60)} MINUTES AGO`
            else if (elapsed < 60 * 60 * 24)
                elapsedString = `${Math.floor(elapsed / (60 * 60))} HOURS AGO`
            else
                elapsedString = `${Math.floor(elapsed / (60 * 60 * 24))} DAYS AGO`
            const txLink = `https://etherscan.io/tx/${log.transactionHash}`
            const owner = '0X' + log.topics[2].slice(26, 28).toUpperCase() + '...' + log.topics[2].slice(62, 66).toUpperCase()
            historyDiv.innerHTML += `<div class='history'> <div class='time-div'> <div class='time'>${elapsedString}</div> <a class='tx' target='_blank' href=${txLink}>[view tx]</a> </div> <div class='address-div'> <div class='text'>Owned by</div> <div class='address'>${owner}</div> </div> </div>`
        })
    }

    getCurrentBlockNumber = (): Promise<number> => {
        return this.web3Provider.getBlockNumber()
    }

    getGhostBalance = (account: string): Promise<number> => {
        return this.contract.balanceOf(account).then((bn: BigNumber) => bn.toNumber())
    }

    getTicketsPerRound = (account: string, blockNumberFrom: number): [Promise<number[]>, Promise<number[]>] => {
        const timeLeftInHour = (startBlock: number, endBlock: number) => {
            return Math.floor(((endBlock - startBlock) * 13.55) / 3600)
        }
        const $hoursLeft = (this.contract.getPioneerRoundExpireBlocks() as Promise<BigNumber[]>).then((expireBlocks: BigNumber[]) => {
            const hoursLeft: number[] = []
            expireBlocks.forEach((expireBlock) => {
                hoursLeft.push(timeLeftInHour(blockNumberFrom, expireBlock.toNumber()))
            })
            return hoursLeft
        })
        const $ticketsAvailable = this.contract.getPioneerTicketAvailablePerRound(account).then((ticketsBN: BigNumber[]) => {
            const tickets: number[] = []
            ticketsBN.forEach((ticketBN) => tickets.push(ticketBN.toNumber()))
            return tickets
        }) as Promise<number[]>
        return [$hoursLeft, $ticketsAvailable]
    }

    getTickets = (account: string): Promise<number> => {
        return this.contract.getPioneerTicketAvailable(account).then((ticketBN: BigNumber) => ticketBN.toNumber())
    }

    getEthBalance = (account: string): Promise<string> => {
        return this.web3Provider.getBalance(account).then((bn: BigNumber) => ethers.utils.formatEther(bn)) as Promise<string>
    }

    getBlockTimestamp = (blockNumber: number): Promise<number> => {
        return this.web3Provider.getBlock(blockNumber).then((block) => block.timestamp)
    }

    getGhostPriceInEth = (): Promise<string> => {
        return this.contract.ghostPrice().then((bn: BigNumber) => ethers.utils.formatEther(bn)) as Promise<string>
    }

    mintGhostForPioneer = async (numMint: number, account: string): Promise<any> => {
        const ghostPrice = await this.contract.ghostPrice()
        const ethNeeded = ethers.utils.hexlify(ghostPrice.mul(numMint))
        const data = this.contractInterface.encodeFunctionData('mintGhostForPioneer', [ethers.utils.hexlify(numMint)])
        return this.ethProvider.request({
            method: 'eth_sendTransaction',
            params: [
                {
                    from: account,
                    to: this.contractAddr,
                    value: ethNeeded,
                    data: data
                }
            ]
        })
    }

    mintGhostPublicSale = async (numMint: number, account: string): Promise<any> => {
        const ghostPrice = await this.contract.ghostPrice()
        const ethNeeded = ethers.utils.hexlify(ghostPrice.mul(numMint))
        const data = this.contractInterface.encodeFunctionData('mintGhost', [ethers.utils.hexlify(numMint)])
        return this.ethProvider.request({
            method: 'eth_sendTransaction',
            params: [
                {
                    from: account,
                    to: this.contractAddr,
                    value: ethNeeded,
                    data: data
                }
            ]
        })
    }

    getFragmentActiveStatus = async (): Promise<number> => {
        if (this.fragmentContractAddr === "")
            return new Promise((resolve, reject) => resolve(0))
        return this.fragmentContract.lotteryState().then((bn: BigNumber) => bn.toNumber())
    }

    getTxReceipt = async (txHash: string): Promise<ethers.providers.TransactionReceipt> => {
        return this.web3Provider.getTransactionReceipt(txHash)
    }

    getGhostHoldings = async (): Promise<Promise<number>[]> => {
        const countHoldings = await this.contract.balanceOf(this.account);
        const promises: Promise<number>[] = []
        for (let i = 0; i < countHoldings; i++) {
            promises.push(
                this.contract.tokenOfOwnerByIndex(this.account, i)
                    .then((bn: BigNumber) => bn.toNumber()) as Promise<number>
            )
        }
        return promises
    }

    pickMemoriesAndRunLottery = async (memoriesData: MemoryData[]): Promise<any> => {
        const ghostIds: number[] = []
        const memoryTypes: number[] = []
        memoriesData.forEach((memoryData) => {
            ghostIds.push(memoryData.ghostId)
            memoryTypes.push(memoryData.memoryType)
        })
        const data = this.storageContractInterface.encodeFunctionData(
            'chooseMemoryAndRunLottery',
            [ghostIds, memoryTypes]
        )
        return this.ethProvider.request({
            method: 'eth_sendTransaction',
            params: [{
                from: this.account,
                to: this.memoriesStorageAddr,
                value: 0,
                data: data,
            }]
        })
    }

    pickMemories = async (memoriesData: MemoryData[]): Promise<any> => {
        const ghostIds: number[] = []
        const memoryTypes: number[] = []
        memoriesData.forEach((memoryData) => {
            ghostIds.push(memoryData.ghostId)
            memoryTypes.push(memoryData.memoryType)
        })
        const data = this.storageContractInterface.encodeFunctionData(
            'chooseMemoryBatch',
            [ghostIds, memoryTypes]
        )
        return this.ethProvider.request({
            method: 'eth_sendTransaction',
            params: [{
                from: this.account,
                to: this.memoriesStorageAddr,
                value: 0,
                data: data,
            }]
        })
    }

    validate = async (ghostIds: number[]): Promise<any> => {
        const promises: Promise<any>[] = []
        for (const ghostId of ghostIds) {
            promises.push(this.storageContract.hasMemory(ghostId).then((hasMemory: boolean) => {
                return {
                    ghostId: ghostId,
                    hasMemory: hasMemory
                }
            }))
        }
        return Promise.all(promises)
    }

    getMemoriesCount = async (): Promise<number[]> => {
        if (this.fragmentContractAddr === "")
            return new Promise((resolve, reject) => resolve([0, 0]))
        const goodMemories: Promise<number> = this.storageContract.countGoodMemories().then((bn: BigNumber) => bn.toNumber())
        const evilMemories: Promise<number> = this.storageContract.countEvilMemories().then((bn: BigNumber) => bn.toNumber())
        return Promise.all([goodMemories, evilMemories])
    }

    getTokenIds = async (): Promise<number[]> => {
        if (this.fragmentContractAddr === "")
            return new Promise((resolve, reject) => resolve([]))
        const balance = await this.fragmentContract.totalSupply().then((bn: BigNumber) => bn.toNumber())
        const tokenIdsPromise: Promise<number>[] = []
        for (let idx = 0; idx < balance; idx++) {
            tokenIdsPromise.push(
                this.fragmentContract.tokenByIndex(idx).then((bn: BigNumber) => bn.toNumber())
            )
        }
        return Promise.all(tokenIdsPromise)
    }

    getFragmentOwner = async (tokenId: number): Promise<string> => {
        return this.fragmentContract.ownerOf(tokenId)
    }

    getOwnedFragments = async (): Promise<number[]> => {
        const balance = await this.fragmentContract.balanceOf(this.account).then((bn: BigNumber) => bn.toNumber())
        const tokenIdsPromise: Promise<number>[] = []
        for (let idx = 0; idx < balance; idx++) {
            tokenIdsPromise.push(
                this.fragmentContract.tokenOfOwnerByIndex(this.account, idx).then((bn: BigNumber) => bn.toNumber())
            )
        }
        return Promise.all(tokenIdsPromise)
    }

    runLottery = (ghostIds: number[]) => {
        const data = this.fragmentContractInterface.encodeFunctionData(
            'runLotteryBatch',
            [ghostIds]
        )
        return this.ethProvider.request({
            method: 'eth_sendTransaction',
            params: [{
                from: this.account,
                to: this.fragmentContractAddr,
                value: 0,
                data: data,
            }]
        })
    }

    waitSummonForTxConfirmation = (txHash: string) => {
        this.web3Provider.once(txHash, (txReceipt: any) => {
            const tmpThis = this
            unloadSummonPhase()
            if (txReceipt.status === 1) {
                // success
                zip(loadSummonPhase('summon_phase4.html')).subscribe(() => {
                    new SummonPhase4(tmpThis, txReceipt)
                })
            } else {
                // fail
                zip(loadSummonPhase('summon_phase5.html')).subscribe(() => {
                    new SummonPhase5(tmpThis, txReceipt)
                })
            }
        })
    }

    waitMemoriesForTxConfirmation = (txHash: string) => {
        this.web3Provider.once(txHash, async (txReceipt: any) => {
            const tmpThis = this
            const openNextPage = () => {
                unloadMemoriesPhase()
                if (txReceipt.status === 1) {
                    // success
                    zip(loadMemoriesPhase('memories_phase6_success.html')).subscribe(() => {
                        new MemoriesPhase6(tmpThis, txReceipt)
                    })
                } else {
                    // fail
                    zip(loadMemoriesPhase('memories_phase6_fail.html')).subscribe(() => {
                        new MemoriesPhase6(tmpThis, txReceipt)
                    })
                }
            }
            console.debug("Tx Confirmed. Wait for 5 secs to update s3 server")
            window.setTimeout(openNextPage, 5_000)
        })
    }

    waitClaimForTxConfirmation = (txHash: string) => {
        this.web3Provider.once(txHash, async (txReceipt: any) => {
            const tmpThis = this
            unloadClaimPhase()
            zip(loadClaimPhase('claim_phase3.html')).subscribe(() => {
                new ClaimPhase3(tmpThis, txReceipt)
            })
        })
    }

    waitForRunLotteryTxConfirmation = (ghostIds: number[], txHash: string) => {
        this.web3Provider.once(txHash, (txReceipt: any) => {
            const tmpThis = this
            unloadMemoriesPhase()
            if (txReceipt.status === 1) {
                // success
                zip(loadMemoriesPhase('memories_phase6_success.html')).subscribe(() => {
                    new MemoriesPhase6(tmpThis, ghostIds, txReceipt)
                })
            } else {
                // fail
                zip(loadMemoriesPhase('memories_phase6_fail.html')).subscribe(() => {
                    new MemoriesPhase6(tmpThis, ghostIds, txReceipt)
                })
            }
        })
    }

    getEtherscanURL = (txHash: string) => {
        let url: string
        if (this.chainId === '0x1') url = `https://etherscan.io/tx/${txHash}`
        else if (this.chainId === '0x4') url = `https://rinkeby.etherscan.io/tx/${txHash}`
        else if (this.chainId === '0x5') url = `https://goerli.etherscan.io/tx/${txHash}`
        return url
    }

    updateFragmentHighlight = () => {
        if (document.getElementById('fragmentsContainer')) {
            this.getOwnedFragments().then((tokenIds: number[]) => {
                tokenIds.forEach((tokenId) => {
                    if (document.getElementById(`fragment#${tokenId}`))
                        document.getElementById(`fragment#${tokenId}`).classList.add('highlight')
                    if (document.getElementById(`fragmentId#${tokenId}`))
                        document.getElementById(`fragmentId#${tokenId}`).classList.add('idHighlight')
                })
            })
        }
    }

    getMemories = (tokenId: number): Promise<string> => {
        return this.storageContract.getChosenMemory(tokenId)
    }

    getMemoryType = (tokenId: number): Promise<string> => {
        return this.storageContract.getMemoryType(tokenId).then((memoryTypeBN: BigNumber) => {
            const memoryType = memoryTypeBN.toNumber()
            if (memoryType === 1)
                return "GOOD"
            else if (memoryType === 2)
                return "EVIL"
            return "Unknown"
        })
    }

    hasCorrectMemory = (tokenId: number): Promise<boolean> => {
        return this.fragmentContract.hasCorrectMemory(tokenId)
    }

    isClaimed = (ghostId: number): Promise<boolean> => {
        return this.specialFragmentContract.claimed(ghostId)
    }

    isClaimable = (ghostId: number): Promise<boolean> => {
        return this.specialFragmentContract.isClaimable(ghostId)
    }

    isVerified = (ghostId: number): Promise<boolean> => {
        return this.specialFragmentContract.isVerified(ghostId)
    }

    getSpecialFragmentCount = (): Promise<number> => {
        return this.specialFragmentContract.getTotalSupply(0).then((bn: BigNumber) => bn.toNumber())
    }

    getClaimableSpecialFragments = async (): Promise<any> => {
        const ghostsPromises = await this.getGhostHoldings()
        const promises: Promise<any>[] = []
        return Promise.all(ghostsPromises).then((ghosts) => {
            ghosts.forEach((ghostId) => {
                promises.push(this.specialFragmentContract.isClaimable(ghostId).then((isClaimbale: boolean) => {
                    return {'ghostId': ghostId, 'isClaimable': isClaimbale}
                }))
            })
            return promises
        })
    }

    claimSpecialFragments = (ghostIds: number[]) => {
        const data = this.specialFragmentContractInterface.encodeFunctionData(
            'claimSpecialFragments',
            [ghostIds]
        )
        return this.ethProvider.request({
            method: 'eth_sendTransaction',
            params: [{
                from: this.account,
                to: this.specialFragmentAddr,
                value: 0,
                data: data,
            }]
        })

    }


// countDownForOpen = () => {
    //     const nowDate: number = new Date().valueOf()
    //     if (this.soldout) {
    //         console.log('soldout')
    //         document.querySelector('#summons')?.classList.add('hidden')
    //         document.querySelector('#saleOpen')?.classList.add('hidden')
    //         document.querySelector('#soldout')?.classList.remove('hidden')
    //     } else if (nowDate < this.targetDate || !this.saleIsActive) {
    //         document.querySelector('#countSection')?.classList.remove('hidden')
    //         document.querySelector('#saleOpen')?.classList.add('hidden')
    //     } else {
    //         document.querySelector('#countSection')?.classList.add('hidden')
    //         document.querySelector('#saleOpen')?.classList.remove('hidden')
    //     }
    //     const daysEle: HTMLHeadElement = document.querySelector('#days')
    //     const hoursEle: HTMLHeadElement = document.querySelector('#hours')
    //     const minutesEle: HTMLHeadElement = document.querySelector('#minutes')
    //
    //     if (!daysEle || !hoursEle || !minutesEle) {
    //         return
    //     }
    //
    //     const setCount = () => {
    //         const nowDate: number = new Date().valueOf()
    //         const elapsed: number = this.targetDate - nowDate
    //
    //         const days = String(Math.floor(elapsed / (1000 * 60 * 60 * 24))).padStart(2, '0') //
    //         const hour = String(Math.floor((elapsed / (1000 * 60 * 60)) % 24)).padStart(2, '0') //
    //         const minutes = String(Math.floor((elapsed / (1000 * 60)) % 60)).padStart(2, '0') // �
    //
    //         daysEle.innerHTML = `${+days < 0 ? '00' : days}`
    //         hoursEle.innerHTML = `${+hour < 0 ? '00' : hour}`
    //         minutesEle.innerHTML = `${+minutes < 0 ? '00' : minutes}`
    //     }
    //     setCount()
    //
    //     setInterval(setCount, 1000)
    // }
}
