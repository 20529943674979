

const extensionMap: { [key: string]: { original: string, timg: string } } = {
    '1':{'original':'png','timg':'png'},
    '2':{'original':'gif','timg':'gif'},
    '3':{'original':'png','timg':'png'},
    '4':{'original':'gif','timg':'gif'},
    '5':{'original':'png','timg':'png'},
    '6':{'original':'png','timg':'png'},
    '7':{'original':'png','timg':'png'},
    '8':{'original':'png','timg':'png'},
    '9':{'original':'png','timg':'png'},
    '10':{'original':'png','timg':'png'},
    '11':{'original':'gif','timg':'gif'},
    '12':{'original':'png','timg':'png'},
    '13':{'original':'gif','timg':'gif'},
    '14':{'original':'png','timg':'png'},
    '15':{'original':'png','timg':'png'},
    '16':{'original':'gif','timg':'gif'},
    '17':{'original':'png','timg':'png'},
    '18':{'original':'png','timg':'png'},
    '19':{'original':'gif','timg':'gif'},
    '20':{'original':'png','timg':'png'},
    '21':{'original':'gif','timg':'gif'},
    '22':{'original':'png','timg':'png'},
    '23':{'original':'png','timg':'png'},
    '24':{'original':'png','timg':'png'},
    '25':{'original':'png','timg':'png'},
    '26':{'original':'png','timg':'png'},
    '27':{'original':'png','timg':'png'},
    '28':{'original':'gif','timg':'gif'},
    '29':{'original':'png','timg':'png'},
    '30':{'original':'gif','timg':'gif'},
    '31':{'original':'png','timg':'png'},
    '32':{'original':'gif','timg':'gif'},
    '33':{'original':'gif','timg':'gif'},
    '34':{'original':'png','timg':'png'},
    '35':{'original':'png','timg':'png'},
    '36':{'original':'gif','timg':'gif'},
    '37':{'original':'gif','timg':'gif'},
    '38':{'original':'png','timg':'png'},
    '39':{'original':'png','timg':'png'},
    '40':{'original':'gif','timg':'gif'},
    '41':{'original':'png','timg':'png'},
    '42':{'original':'png','timg':'png'},
    '43':{'original':'gif','timg':'gif'},
    '44':{'original':'png','timg':'png'},
    '45':{'original':'png','timg':'png'},
    '46':{'original':'png','timg':'png'},
    '47':{'original':'gif','timg':'gif'},
    '48':{'original':'png','timg':'png'},
    '49':{'original':'gif','timg':'gif'},
    '50':{'original':'png','timg':'png'},
    '51':{'original':'png','timg':'png'},
    '52':{'original':'png','timg':'png'},
    '53':{'original':'png','timg':'png'},
    '54':{'original':'png','timg':'png'},
    '55':{'original':'png','timg':'png'},
    '56':{'original':'png','timg':'png'},
    '57':{'original':'png','timg':'png'},
    '58':{'original':'gif','timg':'gif'},
    '59':{'original':'png','timg':'png'},
    '60':{'original':'gif','timg':'gif'},
    '61':{'original':'gif','timg':'gif'},
    '62':{'original':'png','timg':'png'},
    '63':{'original':'gif','timg':'gif'},
    '64':{'original':'png','timg':'png'},
    '65':{'original':'gif','timg':'gif'},
    '66':{'original':'png','timg':'png'},
    '67':{'original':'png','timg':'png'},
    '68':{'original':'gif','timg':'gif'},
    '69':{'original':'png','timg':'png'},
    '70':{'original':'gif','timg':'gif'},
    '71':{'original':'png','timg':'png'},
    '72':{'original':'png','timg':'png'},
    '73':{'original':'gif','timg':'gif'},
    '74':{'original':'png','timg':'png'},
    '75':{'original':'gif','timg':'gif'},
    '76':{'original':'png','timg':'png'},
    '77':{'original':'png','timg':'png'},
    '78':{'original':'png','timg':'png'},
    '79':{'original':'png','timg':'png'},
    '80':{'original':'png','timg':'png'},
    '81':{'original':'mp4','timg':'gif'},
    '82':{'original':'png','timg':'png'},
    '83':{'original':'png','timg':'png'},
    '84':{'original':'png','timg':'png'},
    '85':{'original':'png','timg':'png'},
    '86':{'original':'png','timg':'png'},
    '87':{'original':'gif','timg':'gif'},
    '88':{'original':'png','timg':'png'},
    '89':{'original':'mp4','timg':'gif'},
    '90':{'original':'gif','timg':'gif'},
    '91':{'original':'gif','timg':'gif'},
    '92':{'original':'png','timg':'png'},
    '93':{'original':'png','timg':'png'},
    '94':{'original':'png','timg':'png'},
    '95':{'original':'mp4','timg':'gif'},
    '96':{'original':'png','timg':'png'},
    '97':{'original':'gif','timg':'gif'},
    '98':{'original':'png','timg':'png'},
    '99':{'original':'png','timg':'png'},
    '100':{'original':'png','timg':'png'},
    '101':{'original':'png','timg':'png'},
    '102':{'original':'png','timg':'png'},
    '103':{'original':'png','timg':'png'},
    '104':{'original':'png','timg':'png'},
    '105':{'original':'png','timg':'png'},
    '106':{'original':'gif','timg':'gif'},
    '107':{'original':'png','timg':'png'},
    '108':{'original':'gif','timg':'gif'},
    '109':{'original':'png','timg':'png'},
    '110':{'original':'png','timg':'png'},
    '111':{'original':'png','timg':'png'},
    '112':{'original':'png','timg':'png'},
    '113':{'original':'png','timg':'png'},
    '114':{'original':'gif','timg':'gif'},
    '115':{'original':'png','timg':'png'},
    '116':{'original':'gif','timg':'gif'},
    '117':{'original':'png','timg':'png'},
    '118':{'original':'png','timg':'png'},
    '119':{'original':'png','timg':'png'},
    '120':{'original':'png','timg':'png'},
    '121':{'original':'png','timg':'png'},
    '122':{'original':'png','timg':'png'},
    '123':{'original':'png','timg':'png'},
    '124':{'original':'png','timg':'png'},
    '125':{'original':'png','timg':'png'},
    '126':{'original':'png','timg':'png'},
    '127':{'original':'gif','timg':'gif'},
    '128':{'original':'gif','timg':'gif'},
    '129':{'original':'gif','timg':'gif'},
    '130':{'original':'png','timg':'png'},
    '131':{'original':'png','timg':'png'},
    '132':{'original':'png','timg':'png'},
    '133':{'original':'gif','timg':'gif'},
    '134':{'original':'png','timg':'png'},
    '135':{'original':'png','timg':'png'},
    '136':{'original':'png','timg':'png'},
    '137':{'original':'png','timg':'png'},
    '138':{'original':'gif','timg':'gif'},
    '139':{'original':'mp4','timg':'gif'},
    '140':{'original':'png','timg':'png'},
    '141':{'original':'gif','timg':'gif'},
    '142':{'original':'gif','timg':'gif'},
    '143':{'original':'gif','timg':'gif'},
    '144':{'original':'png','timg':'png'},
    '145':{'original':'png','timg':'png'},
    '146':{'original':'gif','timg':'gif'},
    '147':{'original':'png','timg':'png'},
    '148':{'original':'png','timg':'png'},
    '149':{'original':'gif','timg':'gif'},
    '150':{'original':'png','timg':'png'},
    '151':{'original':'png','timg':'png'},
    '152':{'original':'png','timg':'png'},
    '153':{'original':'png','timg':'png'},
    '154':{'original':'png','timg':'png'},
    '155':{'original':'png','timg':'png'},
    '156':{'original':'png','timg':'png'},
    '157':{'original':'gif','timg':'gif'},
    '158':{'original':'png','timg':'png'},
    '159':{'original':'mp4','timg':'gif'},
    '160':{'original':'png','timg':'png'},
    '161':{'original':'png','timg':'png'},
    '162':{'original':'gif','timg':'gif'},
    '163':{'original':'png','timg':'png'},
    '164':{'original':'png','timg':'png'},
    '165':{'original':'png','timg':'png'},
    '166':{'original':'gif','timg':'gif'},
    '167':{'original':'png','timg':'png'},
    '168':{'original':'png','timg':'png'},
    '169':{'original':'png','timg':'png'},
    '170':{'original':'png','timg':'png'},
    '171':{'original':'gif','timg':'gif'},
    '172':{'original':'png','timg':'png'},
    '173':{'original':'gif','timg':'gif'},
    '174':{'original':'png','timg':'png'},
    '175':{'original':'png','timg':'png'},
    '176':{'original':'png','timg':'png'},
    '177':{'original':'png','timg':'png'},
    '178':{'original':'gif','timg':'gif'},
    '179':{'original':'png','timg':'png'},
    '180':{'original':'png','timg':'png'},
    '181':{'original':'png','timg':'png'},
    '182':{'original':'png','timg':'png'},
    '183':{'original':'gif','timg':'gif'},
    '184':{'original':'png','timg':'png'},
    '185':{'original':'png','timg':'png'},
    '186':{'original':'gif','timg':'gif'},
    '187':{'original':'gif','timg':'gif'}
}


export function getExtension(tokenId: number, isOriginal: boolean) {
    const extensions = extensionMap[tokenId.toString()]
    return isOriginal ? extensions['original'] : extensions['timg']
}