import Metamask from './metamask'
import { loadMemoriesPhase, unloadMemoriesPhase } from '../util/memoriesPhaseLoader'
import { zip } from 'rxjs'
import MemoriesPhase6 from './memoriesPhase6'

export default class MemoriesPhase5 {
    private metamask: Metamask

    constructor(metamask: Metamask, tx: string) { // ghostIds: number[], txs: string[]) {
        this.metamask = metamask

        document.getElementById('memoriesPanel').onclick = (event) => {
            event.stopPropagation()
        }
        document.getElementById('closePopup').onclick = () => {
            document.querySelector('#overlay').classList.add('overlayHidden')
        }

        if (this.metamask.account) {
            document.getElementById('txEtherscanLink').onclick = () => {
                window.open(this.metamask.getEtherscanURL(tx), '_blank').focus();
            }
        }

        this.metamask.waitMemoriesForTxConfirmation(tx)
        // this.metamask.waitMemoriesForTxConfirmation(ghostIds, txs)
    }
}
