import { zip } from 'rxjs'
import Metamask from './metamask'
import { loadMemoriesPhase, unloadMemoriesPhase } from '../util/memoriesPhaseLoader'
import MemoriesPhase2 from './memoriesPhase2'
import { MemoryData, MemoryType } from '../util/memoryData'
import MemoriesPhase4 from './memoriesPhase4'

export default class MemoriesPhase3Manual {
    private metamask: Metamask
    private memories: MemoryData[] = []
    private idx: number = 0

    constructor(metamask: Metamask, ghostIds: number[]) {
        this.metamask = metamask

        document.getElementById('memoriesPanel').onclick = (event) => {
            event.stopPropagation()
        }
        document.getElementById('closePopup').onclick = () => {
            document.getElementById('overlay').classList.add('overlayHidden')
            document.getElementById('memoriesSection').classList.remove('noOverFlow')
            unloadMemoriesPhase()
        }
        document.getElementById('prevStep').onclick = () => {
            if (this.idx === 0) {
                unloadMemoriesPhase()
                zip(loadMemoriesPhase('memories_phase2.html')).subscribe(() => {
                    new MemoriesPhase2(this.metamask, ghostIds)
                })
            } else {
                this.idx -= 1
                this.loadGhostInfo()
                this.updateStatus()
            }
        }
        document.getElementById('nextStep').onclick = () => {
            if (this.isSatisfied(this.idx)) {
                if (this.idx === ghostIds.length - 1) {
                    unloadMemoriesPhase()
                    zip(loadMemoriesPhase('memories_phase4.html')).subscribe(() => {
                        new MemoriesPhase4(this.metamask, this.memories)
                    })
                } else {
                    this.idx += 1
                    this.loadGhostInfo()
                    this.updateStatus()
                }
            }
        }

        document.getElementById('goodMemory').onclick = (event) => {
            event.stopPropagation()
            if (this.memories[this.idx].memoryType === MemoryType.GOOD) {
                this.memories[this.idx].memoryType = MemoryType.UNKNOWN
            } else {
                this.memories[this.idx].memoryType = MemoryType.GOOD
            }
            this.updateStatus()
        }
        document.getElementById('evilMemory').onclick = (event) => {
            event.stopPropagation()
            if (this.memories[this.idx].memoryType === MemoryType.EVIL) {
                this.memories[this.idx].memoryType = MemoryType.UNKNOWN
            } else {
                this.memories[this.idx].memoryType = MemoryType.EVIL
            }
            this.updateStatus()
        }

        if (this.idx < this.memories.length)
            this.updateStatus()

        this.getMemoriesFromWeb3(ghostIds)
    }

    getMemoriesFromWeb3 = async (ghostIds: number[]) => {
        let memories: MemoryData[] = []

        const memoriesPromise: Promise<any>[] = []
        for (const ghostId of ghostIds) {
            for (const memoryType of ["Good", "Evil"])
                memoriesPromise.push(
                    this.metamask.getGhostCandidateMemories(ghostId, memoryType)
                        .then((memories) => {
                            return {
                                id: ghostId,
                                memoryType: memoryType,
                                memories: memories
                            }
                        })
                )
        }

        ghostIds.forEach((ghostId) => {
            memories.push({
                ghostId: ghostId,
                memoryType: MemoryType.UNKNOWN,
                evilMemory: "",
                goodMemory: "",
                evilFlashback: "",
                goodFlashback: ""
            } as MemoryData)
        })

        const memoriesFull = await Promise.all(memoriesPromise)
        memoriesFull.forEach((memory) => {
            const idx = ghostIds.indexOf(memory.id)
            let memoryPiece = memory.memories
            let flashback = ""
            if (memoryPiece.includes("[FB]")) {
                [memoryPiece, flashback] = memoryPiece.split("[FB]").slice(0, 2)
            }
            if (memory.memoryType === "Good") {
                memories[idx].goodMemory = memoryPiece
                memories[idx].goodFlashback = flashback
                if (flashback !== "") {
                    document.getElementById('goodMemory').onmouseover = (event) => {
                        event.stopPropagation()
                        document.getElementById('goodHelpMsgBox').classList.remove('hidden')
                    }
                    document.getElementById('goodMemory').onmouseleave = (event) => {
                        event.stopPropagation()
                        document.getElementById('goodHelpMsgBox').classList.add('hidden')
                    }
                }
            } else {
                memories[idx].evilMemory = memoryPiece
                memories[idx].evilFlashback = flashback
                if (flashback !== "") {
                    document.getElementById('evilMemory').onmouseover = (event) => {
                        event.stopPropagation()
                        document.getElementById('evilHelpMsgBox').classList.remove('hidden')
                    }
                    document.getElementById('evilMemory').onmouseleave = (event) => {
                        event.stopPropagation()
                        document.getElementById('evilHelpMsgBox').classList.add('hidden')
                    }
                }
            }
        })

        this.memories = memories
        this.loadGhostInfo()
    }

    loadGhostInfo = () => {
        const picElem = document.getElementById('ghostPic') as HTMLImageElement
        picElem.src = `${process.env.GHOST_BUCKET}/${this.memories[this.idx].ghostId}.png`
        document.getElementById('pageNum').innerText = `${(this.idx + 1)}/${this.memories.length}`
        document.getElementById('ghostNum').innerText = `#${("000" + this.memories[this.idx].ghostId).slice(-4)}`
        document.getElementById('evilMemoryDesc').innerHTML = `<p>${this.memories[this.idx].evilMemory}</p>`
        if (this.memories[this.idx].evilFlashback !== '') {
            document.getElementById('evilMemoryDesc').appendChild(this._createFlashbackTitle())
            document.getElementById('evilMemoryDesc').innerHTML += `<p>${this.memories[this.idx].evilFlashback}</p>`
        }
        document.getElementById('goodMemoryDesc').innerHTML = `<p>${this.memories[this.idx].goodMemory}</p>`
        if (this.memories[this.idx].goodFlashback !== '') {
            document.getElementById('goodMemoryDesc').appendChild(this._createFlashbackTitle())
            document.getElementById('goodMemoryDesc').innerHTML += `<p>${this.memories[this.idx].goodFlashback}</p>`
        }
    }

    isSatisfied = (idx: number) => {
        return ((idx < this.memories.length) && (this.memories[idx].memoryType !== MemoryType.UNKNOWN))
    }

    updateStatus = () => {
        const ghostMemory = this.memories[this.idx]
        if (ghostMemory.memoryType === MemoryType.GOOD) {
            document.getElementById('evilMemory').classList.remove('highlight')
            document.getElementById('evilChecked').classList.add('hidden')
            document.getElementById('goodMemory').classList.add('highlight')
            document.getElementById('goodChecked').classList.remove('hidden')
        } else if (ghostMemory.memoryType === MemoryType.EVIL) {
            document.getElementById('evilMemory').classList.add('highlight')
            document.getElementById('evilChecked').classList.remove('hidden')
            document.getElementById('goodMemory').classList.remove('highlight')
            document.getElementById('goodChecked').classList.add('hidden')
        } else {
            document.getElementById('evilMemory').classList.remove('highlight')
            document.getElementById('evilChecked').classList.add('hidden')
            document.getElementById('goodMemory').classList.remove('highlight')
            document.getElementById('goodChecked').classList.add('hidden')
        }
        if (this.isSatisfied(this.idx)) {
            document.getElementById('nextStep').classList.remove('nextNotSelected')
        } else {
            document.getElementById('nextStep').classList.add('nextNotSelected')
        }
        if (this.idx === this.memories.length - 1)
            document.getElementById('nextStep').innerHTML = '<p>DONE</p>'
        else
            document.getElementById('nextStep').innerHTML = "<p>NEXT</p><div class='selectArrow'></div>"
    }

    // _createFlashbackHelpElm = () => {
    //     const msgTitle = document.createElement('b')
    //     msgTitle.innerText = 'Congratulations! 🎉 '
    //
    //     const msgDesc = document.createElement('p')
    //     msgDesc.innerText = 'Not every Ghosts sees a Flashback, a glimpse of Ghosts’ Memories before their death.'
    //
    //     const msgBox = document.createElement('div')
    //     msgBox.classList.add('helpMsgBox')
    //     // msgBox.classList.add('hidden')
    //     msgBox.appendChild(msgTitle)
    //     msgBox.appendChild(msgDesc)
    //     msgBox.setAttribute('id', 'goodHelpMsgBox')
    //
    //     const flashbackHelpDiv = document.createElement('div')
    //     flashbackHelpDiv.appendChild(msgBox)
    //     flashbackHelpDiv.classList.add('flashbackHelp')
    //     flashbackHelpDiv.onmouseover = () => {
    //         console.log("mouse on")
    //         msgBox.classList.remove('hidden')
    //     }
    //     flashbackHelpDiv.onmouseleave = () => {
    //         console.log("mouse leave")
    //         msgBox.classList.add('hidden')
    //     }
    //     return flashbackHelpDiv
    // }

    _createFlashbackHelpElm = () => {
        const msgTitle = document.createElement('b')
        msgTitle.innerText = 'Congratulations! 🎉 '

        const msgDesc = document.createElement('p')
        msgDesc.innerText = 'Not every Ghosts sees a Flashback, a glimpse of Ghosts’ Memories before their death.'

        const msgBox = document.createElement('div')
        msgBox.appendChild(msgTitle)
        msgBox.appendChild(msgDesc)
        msgBox.classList.add('helpMsgBox')
        // msgBox.classList.add('hidden')

        return msgBox
    }

    _createFlashbackTitle = () => {
        const title = document.createElement('p')
        title.innerText = 'Flashback'
        const icon = document.createElement('div')
        icon.classList.add('diamondIcon')
        const helpIcon = document.createElement('div')
        helpIcon.classList.add('helpIcon')

        const flashbackDiv = document.createElement('div')
        flashbackDiv.appendChild(title)
        flashbackDiv.appendChild(icon)
        flashbackDiv.append(helpIcon)
        flashbackDiv.classList.add('expcTitle')

        // const helpElm = this._createFlashbackHelpElm()
        // flashbackDiv.onmouseover = (event) => {
        //     event.stopPropagation()
        //     console.log("mouse on")
        //     document.getElementById('goodHelpMsgBox').classList.remove('hidden')
        //     helpElm.classList.remove('hidden')
        // }
        // flashbackDiv.onmouseleave = (event) => {
        //     event.stopPropagation()
        //     console.log("mouse leave")
        //     document.getElementById('goodHelpMsgBox').classList.add('hidden')
        //     helpElm.classList.add('hidden')
        // }
        // flashbackDiv.appendChild(helpElm)

        return flashbackDiv
    }
}
