import SummonPhase1 from './module/summonPhase1'
import SummonPhase2Public from './module/summonPhase2Public'
import { loadSummonPhase, unloadSummonPhase } from './util/summonLoader'
import { zip, Subject } from 'rxjs'
import Metamask from './module/metamask'
import isMobile from 'is-mobile'
import UnderMaintenance from './module/underMaintenance'
import Header from './module/header'

class Index {
    private header: Header;
    private isStartTypeInteraction: boolean
    // private fadeTimer: NodeJS.Timeout
    private metamask: Metamask

    constructor() {
        const initializer = this.initialize
        const eventArray = Array.from(document.getElementsByTagName('div'))
            .filter((elem) => elem.getAttribute('include-html'))
            .map((elem) => {
                const file = elem.getAttribute('include-html')
                const eventEmitter = new Subject()
                fetch(file)
                    .then((response) => {
                        return response.text()
                    })
                    .then((html) => {
                        elem.insertAdjacentHTML('afterend', html)
                        elem.parentElement.removeChild(elem)
                        eventEmitter.next(true)
                    })
                    .catch((err) => {
                        console.error('error in fetching html', file, err)
                    })
                return eventEmitter
            })
        zip(eventArray).subscribe(() => {
            initializer()
        })
    }

    initialize = () => {
        this.header = new Header(null)
        console.log(process.env.MODE)

        this.isStartTypeInteraction = false
        this.startInteraction()
        window.onscroll = () => this.startInteraction()

        this.metamask = this.header.metamask

        document.querySelector('#summonButton')?.addEventListener('click', (event) => {
            if (isMobile()) {
                window.alert('Wallet connection is only supported on the PC. Please try again on the PC.')
                return
            }
            if (!document.querySelector('#summonButton').classList.contains('buttonDisabled')) {
                if (this.metamask.account) {
                    document.querySelector('#overlay').classList.remove('overlayHidden')
                    if (this.metamask.presaleIsActive)
                        this.summons()
                    else  // saleIsActive
                        this.summonsPublic()
                } else {
                    document.querySelector('header').classList.remove('fadeOut')
                    const panel = document.querySelector('#walletPanel')
                    if (this.metamask.account) {
                        this.metamask.account = null
                        document.getElementById('connectWallet').innerText = 'CONNECT WALLET'
                        panel.classList.add('hidden')
                    } else {
                        if (panel.classList.contains('hidden')) panel.classList.remove('hidden')
                        else panel.classList.add('hidden')
                        document.body.dispatchEvent(new MouseEvent('mousemove'))
                    }
                    event.stopPropagation()
                }
            }
        })
    }

    typeSectionCheck = (): boolean => {
        const countSection: HTMLElement = document.querySelector('#countIntro')
        if (!countSection) return false
        const rect = countSection.getBoundingClientRect()
        console.log(rect)
        if (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        )
            return true
        else return false
    }

    typing = (): void => {
        const txt: string = 'Loading ghosts from the past.....'
        const txtArr: string[] = txt.split('')
        let nowIndex: number = 0
        const countIntro: HTMLHeadElement = document.querySelector('#countIntro')
        if (!countIntro || txtArr.length < 1) return

        const setTxt = (): void => {
            const preTxt: string = countIntro.innerHTML ?? ''
            const postTxt: string = `${preTxt}${txtArr[nowIndex] ?? ''}`
            countIntro.innerHTML = postTxt
            nowIndex++
            if (nowIndex > txtArr.length) {
                nowIndex = 0
                clearInterval(setTxtInterval)
                setTimeout(() => {
                    countIntro.innerHTML = ''
                    setTxtInterval = setInterval(setTxt, 150)
                }, 2000)
            }
        }
        let setTxtInterval = setInterval(setTxt, 150)
    }
    startInteraction = () => {
        const isView = this.typeSectionCheck()
        console.log(isView)
        if (!this.isStartTypeInteraction) {
            this.isStartTypeInteraction = true
            this.typing()
            window.onscroll = () => {}
        }
    }

    summons = () => {
        zip(loadSummonPhase('summon_phase1.html')).subscribe(() => {
            new SummonPhase1(this.metamask)
        })
    }

    summonsPublic = () => {
        zip(loadSummonPhase('summon_phase2_public.html')).subscribe(() => {
            new SummonPhase2Public(this.metamask)
        })
    }

    unsummons = () => {
        unloadSummonPhase()
    }

    loadMaintainence = () => {
        zip(loadSummonPhase('under_maintenance.html')).subscribe(() => {
            new UnderMaintenance()
        })
    }
}

new Index()
