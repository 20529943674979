import SummonPhase3 from './summonPhase3'
import { loadSummonPhase, unloadSummonPhase } from '../util/summonLoader'
import { zip } from 'rxjs'
import Metamask from './metamask'

export default class SummonPhase2Public {
    private metamask: Metamask
    private satisfied: boolean = false
    private ghostPrice: number
    private ethBalance: number
    private ticketAvailable: number
    private mintCount: number

    constructor(metamask: Metamask) {
        this.metamask = metamask
        document.getElementById('summonPanel').onclick = (event) => {
            event.stopPropagation()
        }
        document.getElementById('cb').onclick = () => {
            this.updateCondition()
        }
        document.getElementById('mintCount').focus()

        document.getElementById('mintCount').oninput = (event: InputEvent) => {
            const target = event.target as HTMLInputElement
            let value = target.value
            value = value.replace(/[^0-9]/g, '')
            target.value = value
            const mintCount = value.length === 0 ? 0 : parseInt(value)
            if (mintCount > this.ticketAvailable) {
                target.classList.add('red')
            } else {
                target.classList.remove('red')
            }
            this.updateCondition()
            // this.updateMintingFee(mintCount)
            this.mintCount = mintCount
        }
        document.getElementById('nextStep').onclick = () => {
            if (this.satisfied) {
                unloadSummonPhase()
                this.metamask
                    .mintGhostPublicSale(1, this.metamask.account)
                    .then((txHash: any) => {
                        zip(loadSummonPhase('summon_phase3.html')).subscribe(() => {
                            new SummonPhase3(this.metamask, txHash)
                        })
                    })
                    .catch((txError) => {
                        document.querySelector('#overlay').classList.add('overlayHidden')
                        console.error(txError)
                    })  // https://www.npmjs.com/package/eth-rpc-errors
            }
        }
        document.getElementById('closePopup').onclick = () => {
            document.querySelector('#overlay').classList.add('overlayHidden')
            unloadSummonPhase()
        }

        this.getWeb3ClientInfo()
    }

    updateCondition = () => {
        const mintDesc = document.getElementById('mintDesc')
        const mintCount = parseInt((document.getElementById('mintCount') as HTMLInputElement).value)
        const agreeAggrement = (document.getElementById('cb') as HTMLInputElement).checked as boolean
        if (mintCount === 0) {
            mintDesc.innerHTML = '🚫 Quantity of ghosts should be <br/>greater than zero'
            mintDesc.classList.add('red')
            this.satisfied = false
        } else if (this.ethBalance < mintCount * this.ghostPrice) {
            mintDesc.innerText = '🚫 ETH balance is NOT enough'
            mintDesc.classList.add('red')
            this.satisfied = false
        } else if (mintCount > 1) {
            mintDesc.innerText = '🚫 Each transaction needs to be equal to or less than 1 units'
            mintDesc.classList.add('red')
            this.satisfied = false
        } else if (isNaN(mintCount)) {
            mintDesc.innerText = '⚡ Only one ghost can be minted per tx during the public sale.'
            mintDesc.classList.remove('red')
            this.satisfied = false
        } else {
            mintDesc.innerText = '⚡ Only one ghost can be minted per tx during the public sale.'
            mintDesc.classList.remove('red')
            this.satisfied = true
        }
        this.satisfied &&= agreeAggrement
        if (this.satisfied) document.getElementById('nextStep').classList.remove('disabled')
        else document.getElementById('nextStep').classList.add('disabled')
        this.updateMintingFee(mintCount)
    }

    updateMintingFee = (mintCount: number) => {
        document.getElementById('mintingFee').innerText = `${Math.round((mintCount * this.ghostPrice + Number.EPSILON) * 1000) / 1000} ETH`
    }

    getWeb3ClientInfo = async () => {
        document.getElementById('mintingFee').innerText = '0.1 ETH'
        if (this.metamask.account) {
            const ghostPrice = await this.metamask.getGhostPriceInEth()
            const ethBalance = await this.metamask.getEthBalance(this.metamask.account)
            document.getElementById('ghostPrice').innerText = `${Math.round((parseFloat(ghostPrice) + Number.EPSILON) * 1000) / 1000} ETH / Ghost`
            this.ghostPrice = parseFloat(ghostPrice)
            document.getElementById('walletBalance').innerText = `${Math.round((parseFloat(ethBalance) + Number.EPSILON) * 10) / 10} ETH`
            this.ethBalance = parseFloat(ethBalance)
            this.updateCondition()
        }
    }
}
