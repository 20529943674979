
export function abbrevAddr(addr: string): string {
    if (addr.length !== 42)
        return ''
    return addr.slice(0, 6) + '...' + addr.slice(38, 42)
}


export function timestampToUTCString(timestamp: number): string {
    const date = new Date(timestamp * 1000)
    const yearString = date.getUTCFullYear().toString().padStart(4, '0')
    const monthString = (date.getUTCMonth() + 1).toString().padStart(2, '0')
    const dayString = date.getUTCDate().toString().padStart(2, '0')
    const hourString = date.getUTCHours().toString().padStart(2, '0')
    const minuteString = date.getUTCMinutes().toString().padStart(2, '0')
    const secondString = date.getUTCSeconds().toString().padStart(2, '0')
    return `${yearString}-${monthString}-${dayString} ${hourString}:${minuteString}:${secondString} UTC`
}
