import Metamask from './metamask'
import { loadMemoriesPhase, unloadMemoriesPhase } from '../util/memoriesPhaseLoader'
import { zip } from 'rxjs'
import {MemoryData} from "../util/memoryData";
import MemoriesPhase5 from "./memoriesPhase5";

export default class MemoriesPhase4Warning {
    private metamask: Metamask
    private checked: boolean = false

    constructor(metamask: Metamask, memories: MemoryData[]) { // ghostIds: number[], txs: string[]) {
        this.metamask = metamask

        document.getElementById('memoriesPanel').onclick = (event) => {
            event.stopPropagation()
        }
        document.getElementById('closePopup').onclick = () => {
            document.getElementById('overlay').classList.add('overlayHidden')
            document.getElementById('memoriesSection').classList.remove('noOverFlow')
            unloadMemoriesPhase()
        }
        document.getElementById('checkBoxDiv').onclick = () => {
            if (this.checked) {
                this.checked = false
                document.getElementById('checkBox').innerText = ''
                document.getElementById('checkBox').classList.remove('checked')
                document.getElementById('nextStep').classList.add('disabled')
                document.getElementById('nextStep').innerHTML = '<p>👆 Check the box</p>'
            } else {
                this.checked = true
                document.getElementById('checkBox').innerText = '✔'
                document.getElementById('checkBox').classList.add('checked')
                document.getElementById('nextStep').classList.remove('disabled')
                document.getElementById('nextStep').innerHTML = '<p>Confirm</p>'
            }
        }

        document.getElementById('nextStep').onclick = () => {
            if (this.checked) {
                this.metamask.getFragmentActiveStatus().then((status: number) => {
                    if (status === 1) {
                        this.metamask.pickMemoriesAndRunLottery(memories).then((tx: string) => {
                            unloadMemoriesPhase()
                            zip(loadMemoriesPhase('memories_phase5.html')).subscribe(() => {
                                new MemoriesPhase5(this.metamask, tx)
                            })
                        })
                    } else if (status === 2) {
                        this.metamask.pickMemories(memories).then((tx: string) => {
                            unloadMemoriesPhase()
                            zip(loadMemoriesPhase('memories_phase5.html')).subscribe(() => {
                                new MemoriesPhase5(this.metamask, tx)
                            })
                        })
                    }
                })
            }
        }
    }
}
