import { zip } from 'rxjs'
import Metamask from './metamask'
import { loadClaimPhase, unloadClaimPhase } from '../util/claimPhaseLoader'
import ClaimPhase2 from './claimPhase2'

export default class ClaimPhase1 {
    private metamask: Metamask
    private claimableGhosts: number[]

    constructor(metamask: Metamask) {
        this.metamask = metamask

        document.getElementById('claimPanel').onclick = (event) => {
            event.stopPropagation()
        }

        document.getElementById('nextStep').onclick = () => {
            if (this.claimableGhosts && this.claimableGhosts.length > 0) {
                this.metamask.claimSpecialFragments(this.claimableGhosts).then((tx: string) => {
                    unloadClaimPhase()
                    zip(loadClaimPhase('claim_phase2.html')).subscribe(() => {
                        new ClaimPhase2(this.metamask, tx)
                    })
                })
            }
        }

        document.getElementById('closePopup').onclick = () => {
            document.getElementById('overlayClaim').classList.add('overlayHidden')
            document.getElementById('memoriesSection').classList.remove('noOverFlow')
            unloadClaimPhase()
        }

        this.getWeb3Info()
    }

    getWeb3Info = () => {
        const claimableGhosts: number[] = []
        this.metamask.getClaimableSpecialFragments().then((promises: Promise<any>[]) => {
            Promise.all(promises).then((outputList: {ghostId: number, isClaimable: boolean}[]) => {
                outputList.forEach((output) => {
                    if (output.isClaimable) {
                        claimableGhosts.push(output.ghostId)
                    }
                    // console.log(output)
                })
                this.claimableGhosts = claimableGhosts
                if (this.claimableGhosts.length > 0) {
                    document.getElementById('title').innerText = `You are eligible to claim ${claimableGhosts.length} Special Fragments`
                    this.removeSpinner()
                    this.insertMemoriesBox()
                } else {
                    this.removeSpinner()
                    document.getElementById('hasClaimable').classList.add('hidden')
                    document.getElementById('noClaimable').classList.remove('hidden')
                    document.getElementById('nextStep').classList.add('disabled')
                }
            })
        })
    }

    insertMemoriesBox = () => {
        this.claimableGhosts.forEach((ghostId) => {
            this.insertMemoriesItem(ghostId)
        })
    }

    insertMemoriesItem = (ghostId: number) => {
        const imgDiv = document.createElement('div')
        const imgElm = document.createElement('img')
        imgElm.setAttribute('src', `${process.env.GHOST_BUCKET}/${ghostId}.png`)
        imgElm.setAttribute('loading', 'lazy')
        imgElm.setAttribute('alt', '')
        imgDiv.classList.add('imgDiv')
        imgDiv.appendChild(imgElm)

        const textDiv = document.createElement('div')
        textDiv.innerHTML = `<p>#${("000" + ghostId).slice(-4)}</p>`
        textDiv.classList.add('ghostId')

        const memoriesItem = document.createElement('div')
        memoriesItem.appendChild(imgDiv)
        memoriesItem.appendChild(textDiv)
        memoriesItem.classList.add('grid')

        document.getElementById('claimableGhostDiv').appendChild(memoriesItem)
    }

    removeSpinner = () => {
        document.getElementById('loaderDiv').classList.add('hidden')
    }
}
