

export enum MemoryType {
    UNKNOWN,
    GOOD,
    EVIL
}

export type MemoryData = {
    ghostId: number
    memoryType: MemoryType
    goodMemory: string
    evilMemory: string
    goodFlashback: string
    evilFlashback: string
}
