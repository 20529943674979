import { unloadSummonPhase } from '../util/summonLoader'
import Metamask from './metamask'
import { timestampToUTCString } from '../util/string'

type TxLog = {
    topics: string[]
}

type TxReceipt = {
    logs: TxLog[]
    transactionHash: string
    blockNumber: number
}

export default class SummonPhase4 {
    private metamask: Metamask

    constructor(metamask: Metamask, txReceipt: TxReceipt) {
        this.metamask = metamask
        document.getElementById('summonPanel').onclick = (event) => {
            event.stopPropagation()
        }
        document.getElementById('nextStep').onclick = () => {
            unloadSummonPhase()
            document.querySelector('#overlay').classList.add('overlayHidden')
        }
        document.getElementById('closePopup').onclick = () => {
            document.querySelector('#overlay').classList.add('overlayHidden')
            unloadSummonPhase()
        }
        this.getWeb3ClientInfo(txReceipt)
    }

    getWeb3ClientInfo = (txReceipt: TxReceipt) => {
        const tokenIds = this.getTokenIdsFromTxLogs(txReceipt.logs)
        if (tokenIds.length === 1)
            document.getElementById('summonResult').innerText = `✅ Now 1 ghost is summoned`
        else
            document.getElementById('summonResult').innerText = `✅ Now ${tokenIds.length} ghosts are summoned`

        this.metamask.getBlockTimestamp(txReceipt.blockNumber).then((timestamp) => {
            document.getElementById('summonTxTime').innerHTML = timestampToUTCString(timestamp)
        })

        document.getElementById('summonTxHash').innerText = txReceipt.transactionHash
        document.getElementById('summonTxHash').onclick = () => {
            window.open(this.metamask.getEtherscanURL(txReceipt.transactionHash), '_blank').focus();
        }

        document.getElementById('copyTxHash').onclick = () => {
            const text = document.getElementById('summonTxHash').innerText
            navigator.clipboard.writeText(text).then(() => console.log('copied'))
        }
    }

    getTokenIdsFromTxLogs = (logs: TxLog[]): number[] => {
        const tokenIds: number[] = []
        logs.forEach((log: { topics: string[] }) => {
            if (log.topics.length === 4)
                tokenIds.push(parseInt(log.topics[3], 16))
        })
        return tokenIds
    }
}
