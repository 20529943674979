import { zip } from 'rxjs'
import Metamask from './metamask'
import { loadMemoriesPhase, unloadMemoriesPhase } from '../util/memoriesPhaseLoader'
import MemoriesPhase3Auto from './memoriesPhase3Auto'
import MemoriesPhase3Manual from './memoriesPhase3Manual'

export default class MemoriesPhase2 {
    private metamask: Metamask

    constructor(metamask: Metamask, ghostIds: number[]) {
        this.metamask = metamask
        document.getElementById('memoriesPanel').onclick = (event) => {
            event.stopPropagation()
        }
        document.getElementById('closePopup').onclick = () => {
            document.getElementById('overlay').classList.add('overlayHidden')
            document.getElementById('memoriesSection').classList.remove('noOverFlow')
            unloadMemoriesPhase()
        }

        document.getElementById('autoChoose').onclick = () => {
            unloadMemoriesPhase()
            zip(loadMemoriesPhase('memories_phase3_auto.html')).subscribe(() => {
                new MemoriesPhase3Auto(this.metamask, ghostIds)
            })
        }

        document.getElementById('manualChoose').onclick = () => {
            unloadMemoriesPhase()
            zip(loadMemoriesPhase('memories_phase3_manual.html')).subscribe(() => {
                new MemoriesPhase3Manual(this.metamask, ghostIds)
            })
        }
    }
}
