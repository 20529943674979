export default class Navigation {
    private mobOpen: HTMLButtonElement
    private mobClose: HTMLButtonElement
    private header: HTMLElement
    private fadeTimerCallback: () => NodeJS.Timeout
    constructor(fadeTimerCB: () => NodeJS.Timeout, header: HTMLElement) {
        this.mobOpen = document.querySelector('#navMobOpen')
        this.mobClose = document.querySelector('#navMobClose')
        this.header = header
        this.fadeTimerCallback = fadeTimerCB
        this.events()
    }

    events = (): void => {
        this.mobOpen.addEventListener('click', () => this.mobNavigationController())
        this.mobClose.addEventListener('click', () => this.mobNavigationController())
    }

    mobNavigationController = (): void => {
        const el: HTMLDivElement = this.mobOpen.parentElement.querySelector('div')
        const elPos: number = el.getBoundingClientRect().left
        el.style.left = elPos < 0 ? '0' : '-120%'
        if (elPos < 0) {
            clearTimeout(this.fadeTimerCallback())
            document.body.classList.add('noscroll')
        } else {
            document.body.classList.remove('noscroll')
        }
    }
}
