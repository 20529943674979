import { zip } from 'rxjs'
import Metamask from './metamask'
import MemoriesPhase2 from './memoriesPhase2'
import { loadMemoriesPhase, unloadMemoriesPhase } from '../util/memoriesPhaseLoader'

export default class MemoriesPhase1 {
    private metamask: Metamask
    private holdingGhosts: number[] = []
    private availableGhosts: number[] = []
    private selected = new Set()
    private fullGrids: HTMLElement[] = []
    private currentPage: number = 1
    private pageNum: number = 1
    private MAX_GHOSTS_PER_TX = 30

    constructor(metamask: Metamask) {
        this.metamask = metamask

        document.getElementById('memoriesPanel').onclick = (event) => {
            event.stopPropagation()
        }

        document.getElementById('nextStep').onclick = () => {
            if (this.selected.size > 0) {
                const sortedSelected = Array.from(this.selected).sort((a: number, b: number) => a - b) as number[]
                unloadMemoriesPhase()
                zip(loadMemoriesPhase('memories_phase2.html')).subscribe(() => {
                    new MemoriesPhase2(this.metamask, sortedSelected)
                })
            }
        }
        document.getElementById('nextStep').onmouseover = () => {
            if (this.selected.size > 0 && this.selected.size < this.MAX_GHOSTS_PER_TX)
                document.getElementById('submitAlert').classList.remove('hidden')
        }
        document.getElementById('nextStep').onmouseleave = () => {
            if (this.selected.size > 0 && this.selected.size < this.MAX_GHOSTS_PER_TX)
                document.getElementById('submitAlert').classList.add('hidden')
        }
        document.getElementById('closePopup').onclick = () => {
            document.getElementById('overlay').classList.add('overlayHidden')
            document.getElementById('memoriesSection').classList.remove('noOverFlow')
            unloadMemoriesPhase()
        }

        document.getElementById('leftArrow').onclick = () => {
            this.currentPage = this.pageNum - (this.pageNum + 1 - this.currentPage) % this.pageNum
            this.loadGhostsInPage()
            this.updatePageNum()
        }

        document.getElementById('rightArrow').onclick = () => {
            this.currentPage = (this.currentPage % this.pageNum) + 1
            this.loadGhostsInPage()
            this.updatePageNum()
        }
        document.getElementById('selectAllBtn').onclick = () => {
            if (this.selected.size > 0) {
                Array.from(this.selected).forEach((ghostId: number) => {
                    this.updateSelected(ghostId)
                    this.updateGhostStatus(ghostId)
                })
            } else {
                for (let i = 0; i < Math.min(this.MAX_GHOSTS_PER_TX, this.availableGhosts.length); i++) {
                    this.updateSelected(this.availableGhosts[i])
                    this.updateGhostStatus(this.availableGhosts[i])
                }
            }
            this.updateText()
        }

        this.getHoldingGhostsFromWeb3()
        this.updateText()
    }

    getHoldingGhostsFromWeb3 = () => {
        this.metamask.getGhostHoldings().then((promises) => {
            Promise.all(promises).then((holdingGhosts) => {
                holdingGhosts.sort((a, b) => a - b)
                this.holdingGhosts = holdingGhosts

                if (this.holdingGhosts.length > 10) {
                    document.getElementById('leftArrow').classList.remove('hidden')
                    document.getElementById('rightArrow').classList.remove('hidden')
                }
                this.pageNum = Math.ceil(this.holdingGhosts.length / 10)

                if (this.holdingGhosts.length === 0) {
                    document.getElementById('noGhostBackboard').classList.remove('hidden')
                    document.getElementById('title').classList.add('title-disabled')
                } else {
                    document.getElementById('noGhostBackboard').classList.add('hidden')
                    document.getElementById('title').classList.remove('title-disabled')
                }

                this.metamask.validate(this.holdingGhosts).then((validationList) => {
                    const validationMap = new Map()
                    this.availableGhosts = []
                    validationList.forEach((v: {ghostId: number, hasMemory: boolean}) => {
                        validationMap.set(v.ghostId, v.hasMemory)
                        if (!v.hasMemory)
                            this.availableGhosts.push(v.ghostId)
                    })

                    this.reorderGhosts()

                    document.getElementById('explanation').innerText = `${this.availableGhosts.length} Ghosts`
                    this.loadGhosts(this.holdingGhosts, validationMap)
                    this.removeSpinner()
                    if (this.holdingGhosts.length > 0) {
                        this.loadGhostsInPage()
                        this.updatePageNum()
                    }
                })

            })
        })
    }

    removeSpinner = () => {
        document.getElementById('loaderDiv').classList.add('hidden')
    }

    reorderGhosts = () => {
        const newOrdered = [...this.availableGhosts]
        this.holdingGhosts.forEach((ghostId) => {
            if (!newOrdered.includes(ghostId))
                newOrdered.push(ghostId)
        })
        this.holdingGhosts = newOrdered
    }

    loadGhosts = (ghosts: number[], validationMap: Map<number, boolean>) => {
        const grids = []
        ghosts.forEach((ghostId: number) => {
            const grid = this.getGrid(ghostId, validationMap.get(ghostId))
            grids.push(grid)
        })

        const countEmptyGridNeeded = (10 - (grids.length % 10)) % 10
        for (let i = 0; i < countEmptyGridNeeded; i++) {
            grids.push(this.getEmptyGrid())
        }

        this.fullGrids = grids
    }

    loadGhostsInPage = () => {
        const gridBox = document.getElementById('gridBox')
        while (gridBox.lastChild)
            gridBox.removeChild(gridBox.lastChild)

        for (let i = (this.currentPage - 1) * 10; i < this.currentPage * 10; i++)
            gridBox.appendChild(this.fullGrids[i])

        for (let i = 10 * (this.currentPage - 1); i < Math.min(10 * this.currentPage, this.holdingGhosts.length); i++)
            this.updateGhostStatus(this.holdingGhosts[i])

    }
    updatePageNum = () => {
        document.getElementById('pageNum').innerText = `${this.currentPage}/${this.pageNum}`
    }

    getGrid = (ghostId: number, hasMemory: boolean) => {
        const imgDiv = document.createElement('div')
        const imgElem = document.createElement('img')
        const url = `${process.env.GHOST_BUCKET}/${ghostId}.png`
        imgElem.setAttribute('src', url)
        imgElem.setAttribute('loading', 'lazy')
        imgElem.setAttribute('alt', '')
        imgDiv.appendChild(imgElem)
        imgDiv.classList.add('gridPic')
        if (hasMemory) {
            imgElem.classList.add('clickDisabled')
            const doneDiv = document.createElement('div')
            doneDiv.innerHTML = '<p>DONE</p>'
            doneDiv.classList.add('chooseDone')
            imgDiv.appendChild(doneDiv)
        }

        const highlight = document.createElement('div')
        highlight.classList.add('highlight')
        highlight.classList.add('highlightHidden')
        highlight.setAttribute('id', `highlight${ghostId}`)

        const nameDiv = document.createElement('div')
        const name = document.createElement('div')
        name.innerText = `#${('000' + ghostId).slice(-4)}`
        name.classList.add('nameText')
        nameDiv.appendChild(name)
        nameDiv.classList.add('name')
        if (hasMemory) {
            nameDiv.classList.add('nameUl')
            nameDiv.onclick = () => {
                window.open(`/detail.html?id=${ghostId}`, "_blank")
            }
            const helpIcon = document.createElement('div')
            helpIcon.classList.add('helpIcon')
            nameDiv.appendChild(helpIcon)
        }

        const grid = document.createElement('div')
        if (!hasMemory)
            grid.appendChild(highlight)
        grid.appendChild(imgDiv)
        grid.appendChild(nameDiv)
        grid.classList.add('grid')
        grid.setAttribute('id', `ghost${ghostId}`)

        if (!hasMemory) {
            grid.onclick = () => {
                if (this.selected.size < this.MAX_GHOSTS_PER_TX || this.selected.has(ghostId)) {
                    this.updateSelected(ghostId)
                    this.updateGhostStatus(ghostId)
                    this.updateText()
                }
            }
        }
        return grid
    }

    updateSelected = (ghostId: number) => {
        if (!this.selected.has(ghostId))
            this.selected.add(ghostId)
        else
            this.selected.delete(ghostId)
        if (this.selected.size === this.MAX_GHOSTS_PER_TX)
            document.getElementById('submitAlert').classList.remove('hidden')
        else
            document.getElementById('submitAlert').classList.add('hidden')
    }

    updateGhostStatus = (ghostId: number) => {
        const highlight = document.getElementById(`highlight${ghostId}`)
        if (highlight) {
            if (this.selected.has(ghostId) && highlight.classList.contains('highlightHidden'))
                highlight.classList.remove('highlightHidden')
            else if (!this.selected.has(ghostId) && !highlight.classList.contains('highlightHidden'))
                highlight.classList.add('highlightHidden')
        }
        // console.log(this.selected)
    }

    getEmptyGrid = () => {
        const emptyPic = document.createElement('div')
        emptyPic.classList.add('emptyPic')

        const grid = document.createElement('div')
        grid.appendChild(emptyPic)
        grid.classList.add('grid')
        return grid
    }

    updateText = () => {
        if (this.selected.size > 0) {
            document.getElementById('selectTxt').innerText = 'Cancel all'
            document.getElementById('nextStep').classList.remove('disabled')
            document.getElementById('discoverTxt').classList.add('white')
        } else {
            document.getElementById('selectTxt').innerText = 'Select all'
            document.getElementById('nextStep').classList.add('disabled')
            document.getElementById('discoverTxt').classList.remove('white')
        }
        if (this.selected.size > 1) {
            document.getElementById('discoverTxt').innerText = `${this.selected.size} Ghosts'`
        } else {
            document.getElementById('discoverTxt').innerText = `${this.selected.size} Ghost's`
        }
    }

}
