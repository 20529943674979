import Metamask from './metamask'
import { zip } from 'rxjs'
import { timestampToUTCString } from '../util/string'
import { loadClaimPhase, unloadClaimPhase } from '../util/claimPhaseLoader'
import ClaimPhase1 from './claimPhase1'

type Log = {
    address: string
    blockHash: string
    blockNumber: number
    data: string
    logIndex: number
    topics: string[]
    transactionHash: string
    transactionIndex: number
}

type TxReceipt = {
    transactionHash: string
    blockNumber: number
    confirmations: number
    status: number
    logs: Log[]
}

export default class ClaimPhase3 {
    private metamask: Metamask
    private txReceipt: TxReceipt

    constructor(metamask: Metamask, txReceipt: TxReceipt) {
        console.log(txReceipt)
        this.metamask = metamask
        this.txReceipt = txReceipt

        document.getElementById('claimPanel').onclick = (event) => {
            event.stopPropagation()
        }
        document.getElementById('closePopup').onclick = () => {
            document.getElementById('overlayClaim').classList.add('overlayHidden')
            unloadClaimPhase()
        }

        this.metamask.getBlockTimestamp(txReceipt.blockNumber).then((timestamp) => {
            document.getElementById('txTimestamp').innerText = timestampToUTCString(timestamp)
        })

        document.getElementById('txHash').innerText = txReceipt.transactionHash
        document.getElementById('txHash').onclick = () => {
            window.open(this.metamask.getEtherscanURL(txReceipt.transactionHash), '_blank').focus();
        }

        document.getElementById('copyTxHash').onclick = () => {
            const text = document.getElementById('txHash').innerText
            navigator.clipboard.writeText(text).then(() => console.log('copied'))
        }

        if (document.getElementById('tryAgain')) {
            document.getElementById('tryAgain').onclick = () => {
                unloadClaimPhase()
                zip(loadClaimPhase('claim_phase1.html')).subscribe(() => {
                    new ClaimPhase1(this.metamask)
                })
            }
        }

        if (document.getElementById('done')) {
            document.getElementById('done').onclick = () => {
                document.getElementById('overlayClaim').classList.add('overlayHidden')
                unloadClaimPhase()
            }
        }
        this.updateStatus()
    }

    updateStatus = () => {
        console.log(this.txReceipt)
        if (this.txReceipt.status === 0) {
            document.getElementById('done').classList.add('hidden')
            document.getElementById('tryAgain').classList.remove('hidden')
            document.getElementById('receiptTitle').innerText = "FAIL"
            document.getElementById('receiptTitle').classList.add("fail")
            document.getElementById('receiptDesc').innerText = "🚫 Something went wrong in the transaction process."
            document.getElementById('receiptDesc').classList.add("fail")
        } else {
            const data = this.txReceipt.logs[0].data
            const numGhosts = parseInt(data.substr(126, 4), 16)
            document.getElementById('receiptDesc').innerText = `✅ Congratulations! You have claimed ${numGhosts} Special Fragments`
        }
    }
}
