import { zip } from 'rxjs'
import { loadSummonPhase, unloadSummonPhase } from '../util/summonLoader'
import { timestampToUTCString } from '../util/string'
import Metamask from './metamask'
import SummonPhase1 from './summonPhase1'

type TxReceipt = {
    transactionHash: string
    blockNumber: number
}

export default class SummonPhase5 {
    private metamask: Metamask

    constructor(metamask: Metamask, txReceipt: TxReceipt) {
        this.metamask = metamask
        document.getElementById('summonPanel').onclick = (event) => {
            event.stopPropagation()
        }
        document.getElementById('nextStep').onclick = () => {
            unloadSummonPhase()
            zip(loadSummonPhase('summon_phase1.html')).subscribe(() => {
                new SummonPhase1(this.metamask)
            })
        }
        document.getElementById('closePopup').onclick = () => {
            document.querySelector('#overlay').classList.add('overlayHidden')
            unloadSummonPhase()
        }
        this.getWeb3ClientInfo(txReceipt)
    }

    getWeb3ClientInfo = (txReceipt: TxReceipt) => {
        this.metamask.getBlockTimestamp(txReceipt.blockNumber).then((timestamp) => {
            document.getElementById('summonTxTime').innerHTML = timestampToUTCString(timestamp)
        })

        document.getElementById('summonTxHash').innerText = txReceipt.transactionHash
        document.getElementById('summonTxHash').onclick = () => {
            window.open(this.metamask.getEtherscanURL(txReceipt.transactionHash), '_blank').focus();
        }

        document.getElementById('copyTxHash').onclick = () => {
            const text = document.getElementById('summonTxHash').innerText
            navigator.clipboard.writeText(text).then(() => console.log('copied'))
        }
    }
}
