import Metamask from './metamask'
import { unloadSummonPhase } from '../util/summonLoader'

export default class SummonPhase3 {
    private metamask: Metamask

    constructor(metamask: Metamask, txHash: string) {
        this.metamask = metamask
        document.getElementById('summonPanel').onclick = (event) => {
            event.stopPropagation()
        }
        this.getWeb3ClientInfo(txHash)
        this.metamask.waitSummonForTxConfirmation(txHash)
        document.getElementById('closePopup').onclick = () => {
            document.querySelector('#overlay').classList.add('overlayHidden')
            unloadSummonPhase()
        }
    }

    getWeb3ClientInfo = (txHash: string) => {
        if (this.metamask.account) {
            document.getElementById('txEtherscanLink').onclick = () => {
                window.open(this.metamask.getEtherscanURL(txHash), '_blank').focus();
            }
        }
    }
}
