import Metamask from './metamask'
import { zip } from 'rxjs'
import { loadClaimPhase, unloadClaimPhase } from '../util/claimPhaseLoader'
import ClaimPhase3 from './claimPhase3'

export default class ClaimPhase2 {
    private metamask: Metamask

    constructor(metamask: Metamask, tx: string) {
        this.metamask = metamask

        document.getElementById('claimPanel').onclick = (event) => {
            event.stopPropagation()
        }
        document.getElementById('closePopup').onclick = () => {
            document.getElementById('overlayClaim').classList.add('overlayHidden')
            unloadClaimPhase()
        }

        if (this.metamask.account) {
            document.getElementById('txEtherscanLink').onclick = () => {
                window.open(this.metamask.getEtherscanURL(tx), '_blank').focus();
            }
        }

        // unloadClaimPhase()
        // zip(loadClaimPhase('claim_phase3.html')).subscribe(() => {
        //     new ClaimPhase3(this.metamask, {status: 0, transactionHash: "0x1cd835fdc443e1756f32fe0e3337592b4bb4013fa6fe32ef713883796a52f3a3", blockNumber: 1, logs: [], confirmations: 1})
        // })
        this.metamask.waitClaimForTxConfirmation(tx)
    }
}
