import Metamask from './metamask'
import { loadMemoriesPhase, unloadMemoriesPhase } from '../util/memoriesPhaseLoader'
import { MemoryData, MemoryType } from '../util/memoryData'
import { zip } from 'rxjs'
import MemoriesPhase5 from './memoriesPhase5'
import MemoriesPhase4Warning from "./memoriesPhase4Warning";

export default class MemoriesPhase4 {
    private metamask: Metamask
    private memories: MemoryData[]

    constructor(metamask: Metamask, memories: MemoryData[]) {
        this.metamask = metamask
        this.memories = memories

        document.getElementById('memoriesPanel').onclick = (event) => {
            event.stopPropagation()
        }
        document.getElementById('closePopup').onclick = () => {
            document.getElementById('overlay').classList.add('overlayHidden')
            document.getElementById('memoriesSection').classList.remove('noOverFlow')
            unloadMemoriesPhase()
        }

        document.getElementById('nextStep').onmouseover = () => {
            document.getElementById('submitAlert').classList.remove('hidden')
        }
        document.getElementById('nextStep').onmouseleave = () => {
            document.getElementById('submitAlert').classList.add('hidden')
        }
        document.getElementById('nextStep').onclick = () => {
            unloadMemoriesPhase()
            zip(loadMemoriesPhase('memories_phase4_warning.html')).subscribe(() => {
                new MemoriesPhase4Warning(this.metamask, this.memories)
            })
        }

        if (memories.length <= 1)
            document.getElementById('title').innerHTML = `You've selected<br/>${memories.length} Ghost's Memories`
        else
            document.getElementById('title').innerHTML = `You've selected<br/>${memories.length} Ghosts' Memories`

        memories.forEach((memory: MemoryData, idx: number) => {
            this.addMemoryBox(idx)
        })
    }

    getGhostIds = (memoryDataList: MemoryData[]) => {
        const ids: number[] = []
        memoryDataList.forEach((memoryData) => {
            ids.push(memoryData.ghostId)
        })
        return ids
    }

    addMemoryBox = (idx: number) => {
        const memory = this.memories[idx]
        const ghostIdDiv = document.createElement('p')
        ghostIdDiv.classList.add('ghostId')
        ghostIdDiv.innerText = `#${('000' + memory.ghostId).slice(-4)}`

        const memoryDiv = document.createElement('div')
        memoryDiv.setAttribute('id', `memory${idx}`)
        memoryDiv.classList.add('memoryDiv')
        this._updateMemoryBox(memoryDiv, idx)

        const textDiv = document.createElement('div')
        textDiv.appendChild(ghostIdDiv)
        textDiv.appendChild(memoryDiv)
        textDiv.classList.add('textDiv')

        const portrait = document.createElement('img')
        portrait.setAttribute('src', `${process.env.GHOST_BUCKET}/${memory.ghostId}.png`)
        portrait.setAttribute('alt', '')
        const portraitDiv = document.createElement('div')
        portraitDiv.classList.add('portrait')
        portraitDiv.appendChild(portrait)

        const flipDiv = document.createElement('div')
        flipDiv.setAttribute('id', `flip${idx}`)
        flipDiv.classList.add('flipDiv')

        flipDiv.onclick = () => {
            this.memories[idx].memoryType = (this.memories[idx].memoryType === MemoryType.GOOD) ? MemoryType.EVIL : MemoryType.GOOD
            this.updateMemoryBox(idx)
        }

        const memoryBox = document.createElement('div')
        memoryBox.appendChild(portraitDiv)
        memoryBox.appendChild(textDiv)
        memoryBox.appendChild(flipDiv)
        memoryBox.classList.add('memoryBox')

        document.getElementById('selectedMemories').appendChild(memoryBox)
    }

    updateMemoryBox = (idx: number) => {
        const memoryDiv = document.getElementById(`memory${idx}`)
        this._updateMemoryBox(memoryDiv, idx)
    }

    _updateMemoryBox = (memoryDiv: HTMLElement, idx: number) => {
        while (memoryDiv.lastChild)
            memoryDiv.removeChild(memoryDiv.lastChild)

        const memory = this.memories[idx]

        const memoryType = document.createElement('p')
        memoryType.classList.add('memoryType')
        const memoryText = document.createElement('p')
        memoryText.classList.add('memoryText')
        if (memory.memoryType === MemoryType.GOOD) {
            memoryType.classList.add('good')
            memoryType.innerText = 'GOOD'
            memoryText.innerText = memory.goodMemory
        } else if (memory.memoryType === MemoryType.EVIL) {
            memoryType.classList.add('evil')
            memoryType.innerText = 'EVIL'
            memoryText.innerText = memory.evilMemory
        }
        const memoryPart = document.createElement('div')
        memoryPart.appendChild(memoryType)
        memoryPart.appendChild(memoryText)
        memoryPart.classList.add('memory')

        memoryDiv.appendChild(memoryPart)

        if ((memory.memoryType === MemoryType.GOOD && memory.goodFlashback !== "") || (memory.memoryType === MemoryType.EVIL && memory.evilFlashback !== "")) {
            const memoryType = document.createElement('p')
            memoryType.classList.add('memoryType')
            memoryType.classList.add('flashback')
            memoryType.innerText = 'Flashback'
            const memoryText = document.createElement('p')
            memoryText.classList.add('memoryText')
            memoryText.classList.add('flashback-text')
            if (memory.memoryType === MemoryType.GOOD) {
                memoryText.innerText = memory.goodFlashback
            } else if (memory.memoryType === MemoryType.EVIL) {
                memoryText.innerText = memory.evilFlashback
            }

            const flashback = document.createElement('div')
            flashback.appendChild(memoryType)
            flashback.appendChild(memoryText)
            flashback.classList.add('memory')
            memoryDiv.appendChild(flashback)
        }
    }
}
