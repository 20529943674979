import Metamask from './metamask'
import { loadMemoriesPhase, unloadMemoriesPhase } from '../util/memoriesPhaseLoader'
import { zip } from 'rxjs'
import MemoriesPhase1 from './memoriesPhase1'
import { timestampToUTCString } from '../util/string'
import { MemoryType } from '../util/memoryData'
import {getExtension} from "../util/fragmentExtenstion";

type Log = {
    address: string
    blockHash: string
    blockNumber: number
    data: string
    logIndex: number
    topics: string[]
    transactionHash: string
    transactionIndex: number
}

type TxReceipt = {
    transactionHash: string
    blockNumber: number
    confirmations: number
    status: number
    logs: Log[]
}

export default class MemoriesPhase6 {
    private metamask: Metamask
    private ghostIds: number[]
    private fragmentIds: number[]

    constructor(metamask: Metamask, txReceipt: TxReceipt) { //ghostIds: number[], txReceipt: TxReceipt) {
        console.log(txReceipt)
        this.metamask = metamask
        this.ghostIds = this.getGhostIds(txReceipt)
        this.fragmentIds = this.getFragmentIds(txReceipt)

        document.getElementById('memoriesPanel').onclick = (event) => {
            event.stopPropagation()
        }
        document.getElementById('closePopup').onclick = () => {
            // window.open('/memories.html', '_self')
            document.querySelector('#overlay').classList.add('overlayHidden')
            unloadMemoriesPhase()
        }

        this.metamask.getBlockTimestamp(txReceipt.blockNumber).then((timestamp) => {
            document.getElementById('txTimestamp').innerText = timestampToUTCString(timestamp)
        })

        document.getElementById('txHash').innerText = txReceipt.transactionHash
        document.getElementById('txHash').onclick = () => {
            window.open(this.metamask.getEtherscanURL(txReceipt.transactionHash), '_blank').focus();
        }

        document.getElementById('copyHash').onclick = () => {
            const text = document.getElementById('txHash').innerText
            navigator.clipboard.writeText(text).then(() => console.log('copied'))
        }

        if (document.getElementById('tryAgain')) {
            document.getElementById('tryAgain').onclick = () => {
                unloadMemoriesPhase()
                zip(loadMemoriesPhase('memories_phase1.html')).subscribe(() => {
                    new MemoriesPhase1(this.metamask)
                })
            }
        }

        if (document.getElementById('done')) {
            document.getElementById('done').onmouseover = () => {
                document.getElementById('submitAlert').classList.remove('hidden')
                // if (this.fragmentIds.length > 0)
                //     document.getElementById('submitAlert').classList.remove('hidden')
            }
            document.getElementById('done').onmouseleave = () => {
                document.getElementById('submitAlert').classList.add('hidden')
                // if (this.fragmentIds.length > 0)
                //     document.getElementById('submitAlert').classList.add('hidden')
            }
            document.getElementById('done').onclick = () => {
                // window.open('/memories.html', '_self')
                document.querySelector('#overlay').classList.add('overlayHidden')
                unloadMemoriesPhase()
            }
        }

        if (document.getElementById('memoriesBox')) {
            this.insertMemoriesBox()
        }

        if (document.getElementById('fragmentDiv')) {
            if (this.fragmentIds.length > 0) {
                document.getElementById('fragmentDiv').classList.remove('hidden')
                document.getElementById('fragmentDiscoveredCount').appendChild(this._createFlashbackHelpElm())
                this.insertFragmentBox(this.fragmentIds)

            } else {
                document.getElementById('fragmentDiv').classList.add('hidden')
            }
        }
    }

    getGhostIds = (txReceipt: TxReceipt): number[] => {
        const topic = "0x779a836590695f295ef952f4f886b6a67f7db606605b07a8ca810cad30fd1f82"
        const ghostIds: number[] = []
        txReceipt.logs.forEach((log: Log) => {
            if (log.topics[0] === topic)
                ghostIds.push(parseInt(log.topics[1]))
        })
        return ghostIds.sort((a, b) => a - b)
    }

    insertFragmentBox = (fragmentIds: number[]) => {
        if (fragmentIds.length === 1)
            document.getElementById('fragmentDiscoveredCount').innerHTML = `<b>1 Fragment 🎉</b>`
        else
            document.getElementById('fragmentDiscoveredCount').innerHTML = `<b>${fragmentIds.length} Fragments 🎉</b>`
        fragmentIds.forEach((fragmentId) => {
            this.insertFragmentItem(fragmentId)
        })
    }

    insertFragmentItem = (fragmentId: number) => {
        const imgElm = document.createElement('img')
        imgElm.setAttribute('src', `${process.env.PUBLIC_S3_BUCKET}/FR_${("00" + fragmentId).slice(-3)}_timg.${getExtension(fragmentId, false)}?1234`)
        imgElm.setAttribute('loading', 'lazy')
        imgElm.setAttribute('alt', '')

        const gridSize = this.getGridSize(fragmentId)
        const fragmentItem = document.createElement('div')
        fragmentItem.appendChild(imgElm)
        fragmentItem.classList.add('grid')
        fragmentItem.style.setProperty('width', `${gridSize[0]}px`)
        fragmentItem.style.setProperty('height', `${gridSize[1]}px`)

        document.getElementById('fragmentBox').appendChild(fragmentItem)
    }

    insertMemoriesBox = () => {
        if (this.ghostIds.length === 1)
            document.getElementById('memoriesSelectedCount').innerHTML = `<b>1 Ghost’s Memories 💿</b>`
        else
            document.getElementById('memoriesSelectedCount').innerHTML = `<b>${this.ghostIds.length} Ghosts’ Memories 💿</b>`
        this.ghostIds.forEach((ghostId) => {
            this.insertMemoriesItem(ghostId)
        })
    }

    insertMemoriesItem = (ghostId: number) => {
        const imgDiv = document.createElement('div')
        const imgElm = document.createElement('img')
        imgElm.setAttribute('src', `${process.env.GHOST_BUCKET}/${ghostId}.png`)
        imgElm.setAttribute('loading', 'lazy')
        imgElm.setAttribute('alt', '')
        imgDiv.classList.add('imgDiv')
        imgDiv.appendChild(imgElm)

        const textDiv = document.createElement('div')
        textDiv.innerHTML = `<p>#${("000" + ghostId).slice(-4)}</p>`
        textDiv.classList.add('ghostId')

        const memoriesItem = document.createElement('div')
        memoriesItem.appendChild(imgDiv)
        memoriesItem.appendChild(textDiv)
        memoriesItem.classList.add('grid')

        document.getElementById('memoriesBox').appendChild(memoriesItem)
    }

    getFragmentIds = (txReceipt: TxReceipt): number[] => {
        const topic = "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef"
        const fragmentIds: number[] = []
        txReceipt.logs.forEach((log: Log) => {
            if (log.topics[0] === topic)
                fragmentIds.push(parseInt(log.topics[3]))
        })
        return fragmentIds.sort((a, b) => a - b)
    }

    _createFlashbackHelpElm = () => {
        const msgTitle = document.createElement('b')
        msgTitle.innerText = 'Congratulations! 🎉 '

        const msgDesc = document.createElement('p')
        msgDesc.innerText = 'Not every Ghosts sees a Flashback, a glimpse of Ghosts’ Memories before their death.'

        const msgBox = document.createElement('div')
        msgBox.classList.add('helpMsgBox')
        msgBox.classList.add('hidden')
        msgBox.appendChild(msgTitle)
        msgBox.appendChild(msgDesc)

        const flashbackHelpDiv = document.createElement('div')
        flashbackHelpDiv.classList.add('flashbackHelp')
        flashbackHelpDiv.onmouseover = () => {
            msgBox.classList.remove('hidden')
        }
        flashbackHelpDiv.onmouseleave = () => {
            msgBox.classList.add('hidden')
        }
        return flashbackHelpDiv
    }

    getGridSize = (idx: number): number[] => {
        const DEFAULT_SIZE = 116;
        const specialGrids = {
            81: [5, 1],
            82: [2, 2],
            89: [5, 2],
            95: [2, 3],
            105: [2, 3],
            106: [1, 2],
            107: [2, 2],
            108: [3, 1],
            139: [5, 2],
            145: [5, 2],
            151: [2, 1],
            158: [2, 1],
            159: [2, 2],
            163: [2, 1],
            165: [1, 2],
            166: [1, 2],
            167: [3, 2],
            170: [2, 1],
            172: [2, 1],
            174: [2, 1],
            175: [5, 2],
            181: [2, 2],
            182: [1, 2],
            183: [2, 1],
            187: [4, 1]
        }
        if (idx in specialGrids) {
            const gridKey = specialGrids[idx] as number[]
            return [DEFAULT_SIZE * gridKey[0], DEFAULT_SIZE * gridKey[1]]
        }
        return [DEFAULT_SIZE, DEFAULT_SIZE]

    }

}
